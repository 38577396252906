<br-component-page-intro
  title="Badges"
>
  <p>This page documents the badge component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Score badge - 1"
  code="
  &lt;br-badge value=&quot;1&quot;&gt;1&lt;/br-badge&gt;
  ">
  <br-badge value="1">1</br-badge>
</br-styleguide-sample>

<br-styleguide-sample
  title="Score badge - 2"
  code="
  &lt;br-badge value=&quot;2&quot;&gt;2&lt;/br-badge&gt;
  ">
  <br-badge value="2">2</br-badge>
</br-styleguide-sample>

<br-styleguide-sample
  title="Score badge - 3"
  code="
  &lt;br-badge value=&quot;3&quot;&gt;3&lt;/br-badge&gt;
  ">
  <br-badge value="3">3</br-badge>
</br-styleguide-sample>

<br-styleguide-sample
  title="Score badge - 4"
  code="
  &lt;br-badge value=&quot;4&quot;&gt;4&lt;/br-badge&gt;
  ">
  <br-badge value="4">4</br-badge>
</br-styleguide-sample>

<br-styleguide-sample
  title="Score badge - 5"
  code="
  &lt;br-badge value=&quot;5&quot;&gt;5&lt;/br-badge&gt;
  ">
  <br-badge value="5">5</br-badge>
</br-styleguide-sample>
