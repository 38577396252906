import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { DragulaModule } from 'ng2-dragula';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SignInComponent } from './pages/sign-in/sign-in.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { StyleguideComponent } from './pages/styleguide/styleguide.component';
import { ColorsComponent } from './pages/styleguide/colors/colors.component';
import { IconsComponent } from './pages/styleguide/icons/icons.component';
import { TypographyComponent } from './pages/styleguide/typography/typography.component';
import { ButtonSkinsComponent } from './pages/styleguide/button-skins/button-skins.component';
import { AlertComponent as AlertStyleguideComponent } from './pages/styleguide/alert/alert.component';
import { PaginationComponent as PaginationStyleguideComponent } from './pages/styleguide/pagination/pagination.component';
import { CardComponent as CardStyleguideComponent } from './pages/styleguide/card/card.component';
import { InputComponent as InputStyleguideComponent } from './pages/styleguide/input/input.component';
import { ToolbarComponent as ToolbarStyleguideComponent } from './pages/styleguide/toolbar/toolbar.component';
import { NavbarComponent as NavbarStyleguideComponent } from './pages/styleguide/navbar/navbar.component';
import { IntroComponent } from './pages/styleguide/intro/intro.component';
import { ColorLabelComponent as ColorLabelStyleguideComponent } from './pages/styleguide/color-label/color-label.component';
import { BadgeComponent as BadgeStyleguideComponent } from './pages/styleguide/badge/badge.component';
import { BlankslateComponent as BlankslateStyleguideComponent } from './pages/styleguide/blankslate/blankslate.component';
import { ScoringComponent as ScoringStyleguideComponent } from './pages/styleguide/scoring/scoring.component';

import { ButtonComponent } from './components/button/button.component';
import { PrototypeWrapperComponent } from './components/prototype-wrapper/prototype-wrapper.component';
import { ComponentPageIntroComponent } from './components/component-page-intro/component-page-intro.component';
import { StyleguideSampleComponent } from './components/styleguide-sample/styleguide-sample.component';
import { IconComponent } from './components/icon/icon.component';
import { InputComponent } from './components/input/input.component';
import { AlertComponent } from './components/alert/alert.component';
import { PageTreeComponent } from './page-tree/page-tree.component';
import { ButtonTypesComponent } from './pages/styleguide/button-types/button-types.component';
import { CardComponent } from './components/card/card.component';
import { BodyComponent } from './components/card/body/body.component';
import { HeaderComponent } from './components/card/header/header.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ItemComponent } from './components/toolbar/item/item.component';
import { GroupComponent } from './components/toolbar/group/group.component';
import { TitleComponent } from './components/toolbar/title/title.component';
import { BadgeComponent } from './components/badge/badge.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tab/tab.component';
import { TableComponent as TableStyleguideComponent} from './pages/styleguide/table/table.component';
import { ButtonToolbarComponent } from './components/button-toolbar/button-toolbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SelectionControlsComponent } from './pages/styleguide/selection-controls/selection-controls.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { ColorLabelComponent } from './components/color-label/color-label.component';
import { TreeComponent } from './components/tree/tree.component';
import { CheckboxTreeComponent } from './pages/styleguide/checkbox-tree/checkbox-tree.component';

import { ProjectsObjectivesComponent, ProjectsComponent, ObjectivesComponent} from './pages/projects-objectives/index';
import { AvatarComponent as AvatarStyleguideComponent} from './pages/styleguide/avatar/avatar.component';
import { AvatarComponent} from './components/avatar/avatar.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserMenuComponent as UserMenuStyleguideComponent} from './pages/styleguide/user-menu/user-menu.component';
import { ProjectDetailComponent } from './pages/projects-objectives/project-detail/detail.component';
import { ObjectiveDetailComponent } from './pages/projects-objectives/objective-detail/objective-detail.component';
import { ModalComponent } from './components/modal/modal.component';
import { HeaderComponent as ModalHeaderComponent } from './components/modal/header/header.component';
import { BodyComponent as ModalBodyComponent } from './components/modal/body/body.component';
import { FooterComponent as ModalFooterComponent } from './components/modal/footer/footer.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ClientsComponent } from './pages/admin/clients/clients.component';
import { ClientDetailComponent } from './pages/admin/client-detail/client-detail.component';
import { BrainstormComponent, BrainstormObjectivesComponent, BrainstormProjectsComponent, BrainstormObjectivesEmptyComponent, BrainstormObjectivesDefaultComponent } from './pages/projects-objectives/brainstorm/index';
import { DataBrainstormComponent, BrainstormDataGatheringComponent, BrainstormScoringComponent, BrainstormProgramsComponent, BrainstormProgramsAudienceComponent, BrainstormProgramsSkillsComponent, BrainstormProgramsProcessesComponent, BrainstormProgramsDataComponent } from './pages/data/brainstorm/index';
import { DataComponent, DataProgramsComponent, DataProjectsComponent, DataItemsComponent } from './pages/data/index';
import { BrainstormCardComponent } from './components/brainstorm-card/brainstorm-card.component';
import { BrainstormCardComponent as BrainstormCardStyleguideComponent } from './pages/styleguide/brainstorm-card/brainstorm-card.component';
import { ItemComponent as ButtonToolbarItemComponent } from './components/button-toolbar/item/item.component';
import { BrainstormHeaderComponent } from './components/brainstorm-header/brainstorm-header.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { ProjectsComponent as ProjectsRoadmapComponent } from './pages/roadmap/projects/projects.component';
import { ProgramsComponent as ProgramsRoadmapComponent} from './pages/roadmap/programs/programs.component';
import { RoadmapCardComponent } from './components/roadmap-card/roadmap-card.component';
import { RoadmapCardComponent as RoadmapCardStyleguideComponent } from './pages/styleguide/roadmap-card/roadmap-card.component';
import { ScrollSpyDirective } from './pages/data/brainstorm/scroll-spy.directive';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { ScoringComponent } from './components/scoring/scoring.component';
import { ScullyLibModule } from '@scullyio/ng-lib';


@NgModule({
  declarations: [
    AppComponent,
    ButtonComponent,
    PrototypeWrapperComponent,
    SignInComponent,
    CreateAccountComponent,
    StyleguideComponent,
    ColorsComponent,
    IconsComponent,
    TypographyComponent,
    ButtonSkinsComponent,
    ComponentPageIntroComponent,
    StyleguideSampleComponent,
    IconComponent,
    InputComponent,
    AlertComponent,
    AlertStyleguideComponent,
    PageTreeComponent,
    IntroComponent,
    ButtonTypesComponent,
    CardComponent,
    CardStyleguideComponent,
    PaginationStyleguideComponent,
    BodyComponent,
    HeaderComponent,
    InputStyleguideComponent,
    ToolbarStyleguideComponent,
    ToolbarComponent,
    ItemComponent,
    GroupComponent,
    TitleComponent,
    ProjectsComponent,
    BadgeComponent,
    NavbarComponent,
    TabsComponent,
    TabComponent,
    NavbarStyleguideComponent,
    TableStyleguideComponent,
    ButtonToolbarComponent,
    CheckboxComponent,
    SelectionControlsComponent,
    RadioButtonComponent,
    ColorLabelComponent,
    ColorLabelStyleguideComponent,
    TreeComponent,
    CheckboxTreeComponent,
    BadgeStyleguideComponent,
    ProjectsObjectivesComponent,
    ObjectivesComponent,
    AvatarComponent,
    AvatarStyleguideComponent,
    ContextMenuComponent,
    UserMenuComponent,
    UserMenuStyleguideComponent,
    ProjectDetailComponent,
    ObjectiveDetailComponent,
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    AdminComponent,
    ClientsComponent,
    ClientDetailComponent,
    BrainstormComponent,
    BrainstormObjectivesComponent,
    BrainstormProjectsComponent,
    BlankslateStyleguideComponent,
    BrainstormCardComponent,
    BrainstormCardStyleguideComponent,
    ButtonToolbarItemComponent,
    BrainstormHeaderComponent,
    BrainstormObjectivesEmptyComponent,
    BrainstormObjectivesDefaultComponent,
    ReportsComponent,
    DataComponent,
    DataProgramsComponent,
    DataItemsComponent,
    DataProjectsComponent,
    BrainstormDataGatheringComponent,
    BrainstormScoringComponent,
    BrainstormProgramsComponent,
    DataBrainstormComponent,
    RoadmapComponent,
    ProgramsRoadmapComponent,
    ProjectsRoadmapComponent,
    RoadmapCardComponent,
    RoadmapCardStyleguideComponent,
    ScrollSpyDirective,
    SidePanelComponent,
    ScoringComponent,
    ScoringStyleguideComponent,
    BrainstormProgramsAudienceComponent,
    BrainstormProgramsSkillsComponent,
    BrainstormProgramsProcessesComponent,
    BrainstormProgramsDataComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets/icons/' }),
    NgbModule,
    DragulaModule.forRoot(),
    ScullyLibModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
