import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { StyleguideComponent, ColorsComponent, TypographyComponent, IconsComponent, ButtonSkinsComponent, ButtonTypesComponent, IntroComponent } from './pages/styleguide';
import { AlertComponent as AlertStyleguideComponent } from './pages/styleguide/alert/alert.component';
import { CardComponent as CardStyleguideComponent } from './pages/styleguide/card/card.component';
import { InputComponent as InputStyleguideComponent } from './pages/styleguide/input/input.component';
import { ToolbarComponent as ToolbarStyleguideComponent } from './pages/styleguide/toolbar/toolbar.component';
import { NavbarComponent as NavbarStyleguideComponent } from './pages/styleguide/navbar/navbar.component';
import { TableComponent as TableStyleguideComponent } from './pages/styleguide/table/table.component';
import { PaginationComponent as PaginationStyleguideComponent } from './pages/styleguide/pagination/pagination.component';
import { BlankslateComponent as BlankslateStyleguideComponent } from './pages/styleguide/blankslate/blankslate.component';
import { SelectionControlsComponent} from './pages/styleguide/selection-controls/selection-controls.component';
import { ColorLabelComponent as ColorLabelStyleguideComponent } from './pages/styleguide/color-label/color-label.component';
import { CheckboxTreeComponent as CheckboxTreeStyleguideComponent } from './pages/styleguide/checkbox-tree/checkbox-tree.component';
import { BadgeComponent as BadgeStyleguideComponent } from './pages/styleguide/badge/badge.component';
import { ProjectsObjectivesComponent, ProjectsComponent, ObjectivesComponent } from './pages/projects-objectives/index';
import { AvatarComponent as AvatarStyleguideComponent } from './pages/styleguide/avatar/avatar.component';
import { UserMenuComponent as UserMenuStyleguideComponent } from './pages/styleguide/user-menu/user-menu.component';
import { ProjectDetailComponent } from './pages/projects-objectives/project-detail/detail.component';
import { ObjectiveDetailComponent } from './pages/projects-objectives/objective-detail/objective-detail.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ClientsComponent } from './pages/admin/clients/clients.component';
import { ClientDetailComponent } from './pages/admin/client-detail/client-detail.component';
import { BrainstormComponent, BrainstormObjectivesComponent, BrainstormProjectsComponent, BrainstormObjectivesEmptyComponent, BrainstormObjectivesDefaultComponent } from './pages/projects-objectives/brainstorm/index';
import { BrainstormCardComponent as BrainstormCardStyleguideComponent } from './pages/styleguide/brainstorm-card/brainstorm-card.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { DataBrainstormComponent, BrainstormDataGatheringComponent, BrainstormScoringComponent, BrainstormProgramsComponent, BrainstormProgramsAudienceComponent, BrainstormProgramsSkillsComponent, BrainstormProgramsProcessesComponent , BrainstormProgramsDataComponent} from './pages/data/brainstorm/index';
import { DataComponent, DataProgramsComponent, DataProjectsComponent, DataItemsComponent } from './pages/data/index';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { ProjectsComponent as ProjectsRoadmapComponent } from './pages/roadmap/projects/projects.component';
import { ProgramsComponent as ProgramsRoadmapComponent} from './pages/roadmap/programs/programs.component';
import { RoadmapCardComponent as RoadmapCardStyleguideComponent } from './pages/styleguide/roadmap-card/roadmap-card.component';
import { ScoringComponent as ScoringStyleguideComponent } from './pages/styleguide/scoring/scoring.component';

const routes: Routes = [
  { path: 'sign-in', data: { title: 'Sign in'}, component: SignInComponent },
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'create-account', data: { title: 'Create account'}, component: CreateAccountComponent },
  { path: 'admin', data: { title: 'Admin'}, component: AdminComponent,
    children: [
      { path: 'clients', data: { title: 'Clients' }, component: ClientsComponent},
      { path: 'clients/:client', data: { title: 'Client detail' }, component: ClientDetailComponent},
      { path: '', redirectTo: 'clients', pathMatch: 'full' },
    ]
  },
  {
    path: 'projects-objectives', data: { title: 'Projects & Objectives'}, component: ProjectsObjectivesComponent,
    children: [
      { path: '', redirectTo: 'projects', pathMatch: 'full' },
      { path: 'projects', data: { title: 'Projects' }, component: ProjectsComponent},
      { path: 'detail', data: { title: 'Project detail' }, component: ProjectDetailComponent },
      { path: 'objectives', data: { title: 'Objectives' }, component: ObjectivesComponent },
      { path: 'objective-detail', data: { title: 'Objective detail' }, component: ObjectiveDetailComponent },
      { path: 'brainstorm', data: { title: 'Brainstorm' }, component: BrainstormComponent,
      children: [
        { path: 'objectives', data: { title: 'Brainstorm Objectives' }, component: BrainstormObjectivesComponent,
        children: [
          { path: 'empty', data: { title: 'Empty' }, component: BrainstormObjectivesEmptyComponent },
          { path: 'default', data: { title: 'Default' }, component: BrainstormObjectivesDefaultComponent },
          { path: '', redirectTo: 'default', pathMatch: 'full' },
        ]},
        { path: 'projects', data: { title: 'Brainstorm Projects' }, component: BrainstormProjectsComponent },
        { path: '', redirectTo: 'objectives', pathMatch: 'full' },
      ] },
    ]
  },
  { path: 'reports', data: { title: 'Reports'}, component: ReportsComponent },
  { path: 'data', data: { title: 'Data'}, component: DataComponent,
  children: [
    { path: '', redirectTo: 'projects', pathMatch: 'full' },
    { path: 'projects', data: { title: 'Projects'}, component: DataProjectsComponent },
    { path: 'programs', data: { title: 'Programs'}, component: DataProgramsComponent },
    { path: 'items', data: { title: 'Items'}, component: DataItemsComponent },
    { path: 'brainstorm', data: { title: 'Brainstorm'}, component: DataBrainstormComponent,
    children: [
      { path: '', redirectTo: 'data-gathering', pathMatch: 'full' },
      { path: 'data-gathering', data: { title: 'Data gathering'}, component: BrainstormDataGatheringComponent },
      { path: 'scoring', data: { title: 'Scoring'}, component: BrainstormScoringComponent },
      { path: 'programs', data: { title: 'Programs'}, component: BrainstormProgramsComponent,
      children: [
        { path: '', redirectTo: 'audience', pathMatch: 'full' },
        { path: 'audience', data: { title: 'Audience'}, component: BrainstormProgramsAudienceComponent },
        { path: 'skills', data: { title: 'Skills'}, component: BrainstormProgramsSkillsComponent },
        { path: 'processes', data: { title: 'Processes'}, component: BrainstormProgramsProcessesComponent },
        { path: 'data', data: { title: 'Data'}, component: BrainstormProgramsDataComponent },
      ]},
    ]
  },

  ] },
  { path: 'roadmap', data: { title: 'Roadmap'}, component: RoadmapComponent,
    children: [
      { path: 'projects', data: { title: 'Projects' }, component: ProjectsRoadmapComponent},
      { path: 'programs', data: { title: 'Programs' }, component: ProgramsRoadmapComponent},
      { path: '', redirectTo: 'projects', pathMatch: 'full' },
    ] },
  {
    path: 'styleguide', data: { title: 'Styleguide' }, component: StyleguideComponent,
    children: [
      { path: 'intro', data: { title: 'Intro' }, component: IntroComponent },
      { path: 'basics', data: { title: 'Basics', isCategory: true }, children: [
          { path: '', redirectTo: 'colors', pathMatch: 'full' },
          { path: 'colors', data: { title: 'Colors' }, component: ColorsComponent },
          { path: 'typography', data: { title: 'Typography' }, component: TypographyComponent },
          { path: 'icons', data: { title: 'Icons' }, component: IconsComponent }
        ]
      },
      { path: 'bootstrap-components', data: { title: 'Bootstrap components', isCategory: true }, children: [
          { path: '', redirectTo: 'button-skins', pathMatch: 'full' },
          { path: 'button-skins', data: { title: 'Button skins' }, component: ButtonSkinsComponent },
          { path: 'button-types', data: { title: 'Button types' }, component: ButtonTypesComponent },
          { path: 'alerts', data: { title: 'Alert' }, component: AlertStyleguideComponent },
          { path: 'card', data: { title: 'Card' }, component: CardStyleguideComponent },
          { path: 'inputs', data: { title: 'Inputs' }, component: InputStyleguideComponent },
          { path: 'navbar', data: { title: 'Navbar' }, component: NavbarStyleguideComponent },
          { path: 'table', data: { title: 'Table' }, component: TableStyleguideComponent },
          { path: 'pagination', data: { title: 'Pagination' }, component: PaginationStyleguideComponent },
          { path: 'selection-controls', data: { title: 'Selection controls' }, component: SelectionControlsComponent },
          { path: 'badge', data: { title: 'Badge' }, component: BadgeStyleguideComponent },
        ]
      },
      { path: 'bootstrap-custom-components', data: { title: 'Bootstrap custom components', isCategory: true }, children: [
        { path: 'avatar', data: { title: 'Avatar' }, component: AvatarStyleguideComponent },
        { path: 'toolbar', data: { title: 'Toolbar' }, component: ToolbarStyleguideComponent },
        { path: 'scoring', data: { title: 'Scoring' }, component: ScoringStyleguideComponent },
      ]
    },
    { path: 'custom-components', data: { title: 'Custom components', isCategory: true }, children: [
      { path: 'brainstorm-card', data: { title: 'Brainstorm card' }, component: BrainstormCardStyleguideComponent },
      { path: 'checkbox-tree', data: { title: 'Checkbox tree' }, component: CheckboxTreeStyleguideComponent },
      { path: 'color-label', data: { title: 'Color label' }, component: ColorLabelStyleguideComponent },
      { path: 'roadmap-card', data: { title: 'Roadmap card' }, component: RoadmapCardStyleguideComponent },
      { path: 'user-menu', data: { title: 'User menu' }, component: UserMenuStyleguideComponent },
      { path: 'blankslate', data: { title: 'Blankslate' }, component: BlankslateStyleguideComponent },
      ]
      },
      { path: '', redirectTo: 'intro', pathMatch: 'full' }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
