import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-button-toolbar',
  templateUrl: './button-toolbar.component.html',
  host: {'class': 'c-button-toolbar',
  '[class.c-button-toolbar--vertical]': 'vertical',
  }
})
export class ButtonToolbarComponent implements OnInit {
  @Input() vertical?: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
