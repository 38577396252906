<div class="br-prototype-wrapper">
  <div class="br-prototype-wrapper__content">
    <ng-content></ng-content>
  </div>
  <div class="br-prototype-wrapper__nav" *ngIf="isVisible">
    <div class="u-m-2">
      <div class="c-content">
        <p>
          <br-button (click)="isVisible=!isVisible">Close page tree</br-button>
        </p>
        <div class= "c-content">
          <p>Press Ctrl+M to show the page tree.</p>
        </div>
        <h4 class="c-h6">Page index</h4>
        <p>View the <a routerLink="/styleguide">style guide</a>.</p>
      </div>
      <ul>
        <li>
          <h4 class="br-styleguide-bordered-list-header">Documentation</h4>
          <app-page-tree [routes]="routes"></app-page-tree>
        </li>
      </ul>
    </div>
  </div>
</div>
