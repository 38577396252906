<div class="row overflow-hidden compensate-header">
  <div class="col-2 border-right overflow-auto bg-white h-100">
    <div class="mt-4 mb-4">
      <h3 class="c-h3">Items</h3>
    </div>
    <nav id="data-gathering">
      <ng-container *ngFor="let strategy of strategies; let strategyId = index">
        <h4 class="c-h4 navbar-brand">{{strategy.strategy}}</h4>
        <nav class="nav nav-pills flex-column">
          <a *ngFor="let type of strategy.types; let typeId = index" routerLink="." class="nav-link" (click)="scrollTo(prefix + strategyId + '-' + typeId )" [class.active]="currentSection == (prefix + strategyId + '-' + typeId)">{{type.type}}</a>
        </nav>
      </ng-container>
    </nav>
  </div>
  <div class="col-10 p-0 h-100 overflow-auto border-right">
    <br-navbar isFixed="true">
      <div class="row p-0 w-100 h-100">
        <div class="col-3 border-right d-flex align-items-center">
          <h3 class="c-h3 m-0">Audience</h3>
        </div>
        <div class="col-3 border-right d-flex align-items-center">
          <h3 class="c-h3 m-0">Skills</h3>
        </div>
        <div class="col-3 border-right d-flex align-items-center">
          <h3 class="c-h3 m-0">Data</h3>
        </div>
        <div class="col-3 border-right d-flex align-items-center">
          <h3 class="c-h3 m-0">Processes</h3>
        </div>
      </div>
    </br-navbar>
    <div class="compensate-header position-relative overflow-auto" scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)">
      <ng-container *ngFor="let strategy of strategies; let strategyId = index">
        <div *ngFor="let type of strategy.types; let projectId = index" class="c-brainstorm-scroll-item" id="{{(prefix + strategyId + '-' + projectId)}}" [class.active]="currentSection == prefix + strategyId + '-' + projectId">
          <div class="c-brainstorm-scroll-item__header">
            <h4 class="c-brainstorm-scroll-item__title">{{strategy.strategy}} – {{type.type}}</h4>
          </div>
          <div class="c-brainstorm-scroll-item__content row h-100">
            <div class="col-3 p-0 border-right">
              <div class="c-brainstorm-scroll-item__data m-3">
                <ul class="mt-3">
                  <ng-container *ngFor="let item of items">
                    <li *ngIf="item.strategy == strategy.strategy && item.type == type.type && item.subject == 'Audience'">
                      <div class="mb-2">
                        <br-brainstorm-card type="scoring" score="{{item.score}}" description="{{item.item}}"></br-brainstorm-card>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="col-3 p-0 border-right">
              <div class="c-brainstorm-scroll-item__data m-3">
                <ul class="mt-3">
                  <ng-container *ngFor="let item of items">
                    <li *ngIf="item.strategy == strategy.strategy && item.type == type.type && item.subject == 'Skills'">
                      <div class="mb-2">
                        <br-brainstorm-card type="scoring" score="{{item.score}}" description="{{item.item}}"></br-brainstorm-card>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="col-3 p-0 border-right">
              <div class="c-brainstorm-scroll-item__data m-3">
                <ul class="mt-3">
                  <ng-container *ngFor="let item of items">
                    <li *ngIf="item.strategy == strategy.strategy && item.type == type.type && item.subject == 'Data'">
                      <div class="mb-2">
                        <br-brainstorm-card type="scoring" score="{{item.score}}" description="{{item.item}}"></br-brainstorm-card>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="col-3 p-0 border-right">
              <div class="c-brainstorm-scroll-item__data m-3">
                <ul class="mt-3">
                  <ng-container *ngFor="let item of items">
                    <li *ngIf="item.strategy == strategy.strategy && item.type == type.type && item.subject == 'Processes'">
                      <div class="mb-2">
                        <br-brainstorm-card type="scoring" score="{{item.score}}" description="{{item.item}}"></br-brainstorm-card>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
