<div class="row c-roadmap-header sticky-top border-bottom">
  <div class="col-3 h-100 border-right bg-white">
    <h5 class="c-roadmap-header__label">Period 1</h5>
  </div>
  <div class="col-3 h-100 border-right bg-white">
    <h5 class="c-roadmap-header__label">Period 2</h5>
  </div>
  <div class="col-3 h-100 border-right bg-white">
    <h5 class="c-roadmap-header__label">Period 3</h5>
  </div>
  <div class="col-3 h-100 bg-white">
    <h5 class="c-roadmap-header__label">Period 4</h5>
  </div>
</div>

<ng-container *ngFor="let category of categories">
  <div class="row c-roadmap-header c-roadmap-header--collapsable">
    <br-button *ngIf="category.collapsed" (click)="category.collapsed = !category.collapsed" size="small" skin="tertiary" icon="caret-right" layout="icon-only">Toggle</br-button>
    <br-button *ngIf="!category.collapsed" (click)="category.collapsed = !category.collapsed" size="small" skin="tertiary" icon="caret-down" layout="icon-only">Toggle</br-button>
    <h5 class="c-roadmap-header__label">{{category.subject}}</h5>
  </div>

  <ng-container *ngIf="!category.collapsed">
    <div class="row pl-2">
      <div class="col-3 border-right">
        <ng-container *ngFor="let item of programs">
          <br-roadmap-card *ngIf="item.period == 1 && item.subject == category.subject" (click)="sidePanelOpen=true && programName = item.program" name="{{item.program}}" period="{{item.length}}" score="{{item.score}}" periodChange="{{item.override}}"></br-roadmap-card>
        </ng-container>
      </div>
      <div class="col-3 border-right">
        <ng-container *ngFor="let item of programs">
          <br-roadmap-card *ngIf="item.period == 2 && item.subject == category.subject" (click)="sidePanelOpen=true && programName = item.program" name="{{item.program}}" period="{{item.length}}" score="{{item.score}}" periodChange="{{item.override}}"></br-roadmap-card>
        </ng-container>
      </div>
      <div class="col-3 border-right">
        <ng-container *ngFor="let item of programs">
          <br-roadmap-card *ngIf="item.period == 3 && item.subject == category.subject" (click)="sidePanelOpen=true && programName = item.program" name="{{item.program}}" period="{{item.length}}" score="{{item.score}}" periodChange="{{item.override}}"></br-roadmap-card>
        </ng-container>
      </div>
      <div class="col-3 border-right">
        <ng-container *ngFor="let item of programs">
          <br-roadmap-card *ngIf="item.period == 4 && item.subject == category.subject" (click)="sidePanelOpen=true && programName =item.program" name="{{item.program}}" period="{{item.length}}" score="{{item.score}}" periodChange="{{item.override}}"></br-roadmap-card>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>


<div class="c-side-panel" *ngIf="sidePanelOpen">
  <div class="c-side-panel__header">
    <div class="d-flex">
      <h3 class="c-side-panel__title">Marketing Data</h3>
      <br-badge value="4">4</br-badge>
    </div>
    <br-button (click)="sidePanelOpen=false" skin="tertiary" icon="close" layout="icon-only">Close</br-button>
  </div>

  <div class="c-side-panel__body">
    <ul class="list-group list-group-flush">
      <li *ngFor="let item of getDetailItems(programName)" class="list-group-item d-flex align-items-center justify-content-between">
        <span>{{item.item}}</span>
        <br-badge value="{{item.score}}">{{item.score}}</br-badge>
      </li>
    </ul>
  </div>
</div>
