<ng-container [ngSwitch]="this.type">
  <ng-container *ngSwitchCase="'default'">
    <ng-container *ngIf="!edit">
      <div class="c-brainstorm-card__inner" (click)="edit=true">
        <span class="c-brainstorm-card__type">{{subTitle}}</span>
        <div class="c-brainstorm-card__content">
          <p>{{description}}</p>
        </div>
      </div>
      <div class="c-brainstorm-card__buttons">
        <br-button-toolbar vertical="true">
          <br-button-toolbar-item>
            <br-button size="small" dataToggle="modal" dataTarget="#editItem" icon="edit" layout="icon-only">Edit</br-button>
          </br-button-toolbar-item>
          <br-button-toolbar-item>
            <br-button size="small" dataToggle="modal" dataTarget="#deleteItem" icon="delete" layout="icon-only">Delete</br-button>
          </br-button-toolbar-item>
        </br-button-toolbar>
      </div>
    </ng-container>

    <ng-container *ngIf="edit">
      <div class="c-brainstorm-card__inner--edit">
        <div class="c-brainstorm-card__inner">
          <form>
            <div class="form-group">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3">{{description}}</textarea>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <br-button-toolbar>
              <br-button-toolbar-item>
                <br-button (click)="edit=false">Cancel</br-button>
              </br-button-toolbar-item>
              <br-button-toolbar-item>
                <br-button skin="primary" (click)="edit=false">Save</br-button>
              </br-button-toolbar-item>
            </br-button-toolbar>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'data-gathering'">
    <ng-container *ngIf="!edit">
      <div class="c-brainstorm-card__inner" (click)="edit=true">
        <div class="c-brainstorm-card__content">
          <p>{{description}}</p>
        </div>
      </div>
      <div class="c-brainstorm-card__buttons">
        <br-button-toolbar-item>
          <br-button size="small" dataToggle="modal" dataTarget="#deleteItem" icon="delete" layout="icon-only">Delete</br-button>
        </br-button-toolbar-item>
      </div>
    </ng-container>

    <ng-container *ngIf="edit">
      <div class="c-brainstorm-card__inner--edit">
        <div class="c-brainstorm-card__inner">
          <form>
            <div class="form-group">
              <input id="id" type="text" class="form-control" value="{{description}}">
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <br-button-toolbar>
              <br-button-toolbar-item>
                <br-button (click)="edit=false">Cancel</br-button>
              </br-button-toolbar-item>
              <br-button-toolbar-item>
                <br-button skin="primary" (click)="edit=false">Save</br-button>
              </br-button-toolbar-item>
            </br-button-toolbar>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'scoring'">
    <div class="c-brainstorm-card__overlay"></div>
    <div class="c-brainstorm-card__inner">
      <div class="c-brainstorm-card__content">
        <p>{{description}}</p>
      </div>
          <br-badge value="{{score}}">{{score}}</br-badge>
    </div>
    <div class="c-brainstorm-card__scoring">
      <br-scoring></br-scoring>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'program'">
    <div class="c-brainstorm-card__inner">
      <div class="c-brainstorm-card__content">
        <p>{{description}}</p>
      </div>
          <br-badge value="{{score}}">{{score}}</br-badge>
    </div>
  </ng-container>
</ng-container>
