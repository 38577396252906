<ng-container *ngIf="!hasRoute('brainstorm')">
  <br-navbar skin="key-700" isFixed="true" level="1">
    <br-toolbar>
      <br-toolbar-group position="left">
        <br-toolbar-item>
          <br-context-menu></br-context-menu>
        </br-toolbar-item>
        <br-toolbar-item>
          <br-tabs skin="dark">
            <br-tab route="/projects-objectives">Projects &amp; Objectives</br-tab>
            <br-tab route="/data">Data</br-tab>
            <br-tab route="/reports">Reports</br-tab>
            <br-tab route="/roadmap">Roadmap</br-tab>
          </br-tabs>
        </br-toolbar-item>
      </br-toolbar-group>
      <br-toolbar-group position="right">
        <br-toolbar-item>
          <br-user-menu name="John Doe" company="Innacco" image="./assets/person.jpg"></br-user-menu>
        </br-toolbar-item>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>
</ng-container>

<div class="compensate-header">
  <router-outlet></router-outlet>
</div>
