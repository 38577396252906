
<br-component-page-intro title="Icons">
  <p>This page documents the different icons.</p>
</br-component-page-intro>

<ul class="br-styleguide-icon-list">
  <li *ngFor="let icon of icons">
      <br-icon name="{{icon}}"></br-icon>
      <span>{{icon}}</span>
  </li>
</ul>
