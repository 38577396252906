import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'br-modal-header',
  templateUrl: './header.component.html',
  host: {'class': 'modal-header'}
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
