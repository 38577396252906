<br-component-page-intro
  title="Toolbar"
>
  <p>This page documents the toolbar component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Default"
  code="
  &lt;br-toolbar&gt;
    &lt;br-toolbar-group position=&quot;left&quot;&gt;
      &lt;br-toolbar-item&gt;
        &lt;h3 class=&quot;bc-toolbar__title&quot;&gt;Toolbar title&lt;/h3&gt;
      &lt;/br-toolbar-item&gt;
    &lt;/br-toolbar-group&gt;
    &lt;br-toolbar-group position=&quot;right&quot;&gt;
      &lt;br-toolbar-item&gt;
        &lt;br-button&gt;Button label&lt;/br-button&gt;
      &lt;/br-toolbar-item&gt;
      &lt;br-toolbar-item&gt;
        &lt;br-button&gt;Button label&lt;/br-button&gt;
      &lt;/br-toolbar-item&gt;
    &lt;/br-toolbar-group&gt;
  &lt;/br-toolbar&gt;
  ">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <h3 class="bc-toolbar__title">Toolbar title</h3>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-button>Button label</br-button>
      </br-toolbar-item>
      <br-toolbar-item>
        <br-button>Button label</br-button>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-styleguide-sample>
