import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-color-label',
  templateUrl: './color-label.component.html'
})
export class ColorLabelComponent implements OnInit {
  @Input() color?: string;
  constructor() { }

  ngOnInit(): void {
  }

}
