<br-navbar isFixed="true">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <h3 class="bc-toolbar__title">Projects &amp; Objectives</h3>
      </br-toolbar-item>
      <br-toolbar-item>
        <br-tabs>
          <br-tab route="/projects-objectives/projects">Projects</br-tab>
          <br-tab route="/projects-objectives/objectives">Objectives</br-tab>
        </br-tabs>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-button routerLink="/projects-objectives/brainstorm/projects">Go to brainstorm</br-button>
      </br-toolbar-item>
      <br-toolbar-item>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addProject">
          <br-icon name="add"></br-icon>
          <div class="btn-label">Add project</div>
        </button>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<div class="compensate-header d-flex flex-column overflow-auto flex-nowrap">
  <table class="table">
    <thead>
      <tr>
        <th class="table-cell-icon" scope="col"></th>
        <th scope="col">Name</th>
        <th scope="col">Strategy</th>
        <th scope="col">Project type</th>
        <th scope="col">Budget</th>
        <th scope="col">Business value</th>
        <th class="table-cell-icon" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let project of projects">
        <tr>
          <td>
            <ng-container *ngFor="let objective of projectsObjectives">
              <br-button *ngIf="project.project == objective.project && !project.collapsed" (click)="project.collapsed = !project.collapsed" skin="tertiary" icon="caret-down" layout="icon-only">Toggle</br-button>
              <br-button *ngIf="project.project == objective.project && project.collapsed" (click)="project.collapsed = !project.collapsed" skin="tertiary" icon="caret-right" layout="icon-only">Toggle</br-button>
            </ng-container>
          </td>
          <td>{{project.project}}<br /> <span class="u-muted c-body-2">{{project.description}}</span></td>
          <td class="u-muted">{{project.strategy}}</td>
          <td class="u-muted">{{project.type}}</td>
          <td class="u-muted">{{project.budget | currency: 'EUR'}}</td>
          <td>
            <br-badge value="{{project.businessValue}}">{{project.businessValue}}</br-badge>
          </td>
          <td>
            <br-button-toolbar>
              <br-button routerLink="/projects-objectives/detail" skin="tertiary" icon="chevron-right" layout="icon-only">To detail</br-button>
            </br-button-toolbar>
          </td>
        </tr>
        <ng-container *ngFor="let objective of projectsObjectives">
          <tr *ngIf="project.project == objective.project && !project.collapsed" class="c-table__collapse-content">
            <td colspan="99" class="p-0">
              <table>
                <thead>
                  <tr>
                    <th class="table-cell-icon"></th>
                    <th scope="col">Name</th>
                    <th scope="col">% Attribution to objective</th>
                    <th scope="col">Objective value</th>
                    <th class="table-cell-icon" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of objective.objectives">
                    <td></td>
                    <td>{{item.objective}}<br /> <span class="u-muted c-body-2">{{item.description}}</span></td>
                    <td class="u-muted">{{item.attribution | percent}}</td>
                    <td>
                      <br-badge value="{{item.businessValue}}">{{item.businessValue}}</br-badge>
                    </td>
                    <td colspan="3">
                      <br-button-toolbar>
                        <br-button routerLink="/projects-objectives/objective-detail" skin="tertiary" icon="chevron-right" layout="icon-only">To detail</br-button>
                      </br-button-toolbar>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colspan="6">
                      <br-button size="small" icon="add">Add objective to project</br-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>


<br-modal idName="addProject">
  <br-modal-header>Add project</br-modal-header>
  <br-modal-body>
    <form>
      <div class="form-group">
        <label for="projectName">Name</label>
        <input id="projectName" type="text" class="form-control">
      </div>
    </form>
  </br-modal-body>
  <br-modal-footer>
    <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancel</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">
      <br-icon name="add"></br-icon>
      <div class="btn__label">Add project</div>
    </button>
  </br-modal-footer>
</br-modal>
