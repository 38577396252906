import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  selector: 'br-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.scss']
})
export class ObjectivesComponent implements OnInit {
  
  objectives = DataHandler.getData('projects-objectives/objectives/objectives')

  constructor() { }

  ngOnInit(): void {
  }

}
