<br-component-page-intro
  title="Button types"
>
  <p>This page documents the different button types.</p>
</br-component-page-intro>

<br-styleguide-sample
  title="Default"
  code="&lt;br-button&gt;Button label&lt;/br-button&gt;">
  <br-button>Button label</br-button>
</br-styleguide-sample>

<br-styleguide-sample
  title="Icon only"
  code="&lt;br-button icon=&quot;add&quot; layout=&quot;icon-only&quot;&gt;Add&lt;/br-button&gt;">
  <br-button icon="add" layout="icon-only">Add</br-button>
</br-styleguide-sample>

<br-styleguide-sample
  title="Icon left"
  code="&lt;br-button icon=&quot;add&quot;&gt;Button label&lt;/br-button&gt;">
  <br-button icon="add">Button label</br-button>
</br-styleguide-sample>

<br-styleguide-sample
  title="Icon right"
  code="&lt;br-button icon=&quot;add&quot; layout=&quot;icon-right&quot;&gt;Button label&lt;/br-button&gt;">
  <br-button icon="add" layout="icon-right">Button label</br-button>
</br-styleguide-sample>

<br-styleguide-sample
  title="Block button"
  code="&lt;br-button block=&quot;true&quot;&gt;Button label&lt;/br-button&gt;">
  <br-button block="true">Button label</br-button>
</br-styleguide-sample>

<h4>Parameters</h4>

<table class="table table-striped">
  <thead>
    <th>Name</th>
    <th>Possible value</th>
    <th>Description</th>
  </thead>
  <tbody>
    <tr>
      <td>layout</td>
      <td>icon-only</td>
      <td>Changes the button to only contain an icon</td>
    </tr>
    <tr>
      <td>block</td>
      <td>true</td>
      <td>Changes the button to render as a block button</td>
    </tr>
  </tbody>
</table>