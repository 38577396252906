import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'br-button-toolbar-item',
  templateUrl: './item.component.html',
  host: {'class': 'c-button-toolbar__item'}
})
export class ItemComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
