<br-navbar skin="key-700" isFixed="true" level="1">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <br-context-menu></br-context-menu>
      </br-toolbar-item>
      <br-toolbar-item>
        <br-tabs skin="dark">
          <br-tab route="/projects-objectives">Projects &amp; Objectives</br-tab>
          <br-tab route="/data">Data</br-tab>
          <br-tab route="/reports">Reports</br-tab>
          <br-tab route="/roadmap">Roadmap</br-tab>
        </br-tabs>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-user-menu name="John Doe" company="Innacco" image="./assets/person.jpg"></br-user-menu>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<div class="compensate-header">
  <br-navbar isFixed="true">
    <br-toolbar>
      <br-toolbar-group position="left">
        <br-toolbar-item><h3 class="bc-toolbar__title">Reports</h3></br-toolbar-item>
        <br-toolbar-item>
          <br-tabs>
            <br-tab active="true">Project value</br-tab>
            <br-tab route="/.">Report 2</br-tab>
            <br-tab route="/.">Report 3</br-tab>
          </br-tabs>
        </br-toolbar-item>
      </br-toolbar-group>
      <br-toolbar-group position="right">
        <br-toolbar-item><br-button>Export</br-button></br-toolbar-item>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>

  <div class="compensate-header d-flex flex-column overflow-auto flex-nowrap">
  <div class="row h-100">
    <div class="col-9 border-right bg-white h-100 pl-0">
      <br-navbar border="none">
        <br-toolbar>
          <br-toolbar-group position="left">
            <br-toolbar-item><h3 class="bc-toolbar__title">Project value</h3></br-toolbar-item>
          </br-toolbar-group>
        </br-toolbar>
      </br-navbar>
      <img class="pl-4 pr-4" src="./assets/graph.png" width="100%"/>
    </div>
    <div class="col-3 h-100">
      <br-navbar border="none" skin="gray-50">
        <br-toolbar>
          <br-toolbar-group position="left">
            <br-toolbar-item><h3 class="bc-toolbar__title">Filter</h3></br-toolbar-item>
          </br-toolbar-group>
        </br-toolbar>
      </br-navbar>
      <br-tree [nodes]="nodes"></br-tree>
    </div>
  </div>
  </div>

</div>
