import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-brainstorm-header',
  templateUrl: './brainstorm-header.component.html',
  host: {'class': 'c-brainstorm-header'}
})
export class BrainstormHeaderComponent implements OnInit {
  @Input() title?: string;
  @Input() color?: string = "#B172EF";
  @Input() hover?: boolean = false;
  @Input() edit?: boolean = false;
  @Input() type?: string = 'default';
  constructor() { }

  ngOnInit(): void {
  }

}
