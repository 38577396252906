<br-component-page-intro
  title="User menu"
>
  <p>This page documents the user menu component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Default"
  code="
  &lt;br-navbar skin=&quot;key-700&quot;&gt;
    &lt;br-toolbar&gt;
      &lt;br-toolbar-group position=&quot;right&quot;&gt;
        &lt;br-user-menu name=&quot;John Doe&quot; company=&quot;Innacco&quot; initials=&quot;JD&quot;&gt;&lt;/br-user-menu&gt;
      &lt;/br-toolbar-group&gt;
    &lt;/br-toolbar&gt;
  &lt;/br-navbar&gt;
  ">
  <br-navbar skin="key-700">
    <br-toolbar>
      <br-toolbar-group position="right">
        <br-user-menu name="John Doe" company="Innacco" initials="JD"></br-user-menu>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>
</br-styleguide-sample>

<br-styleguide-sample
  title="Default"
  code="
  &lt;br-navbar skin=&quot;key-700&quot;&gt;
    &lt;br-toolbar&gt;
      &lt;br-toolbar-group position=&quot;right&quot;&gt;
        &lt;br-user-menu name=&quot;John Doe&quot; company=&quot;Innacco&quot; image=&quot;./assets/person.jpg&quot;&gt;&lt;/br-user-menu&gt;
      &lt;/br-toolbar-group&gt;
    &lt;/br-toolbar&gt;
  &lt;/br-navbar&gt;
  ">
  <br-navbar skin="key-700">
    <br-toolbar>
      <br-toolbar-group position="right">
        <br-user-menu name="John Doe" company="Innacco" image="./assets/person.jpg"></br-user-menu>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>
</br-styleguide-sample>
