<br-component-page-intro
  title="Brainstorm"
>
  <p>This page documents the brainstorm components.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Brainstorm card"
  code="&lt;br-brainstorm-card description=&quot;This is an objective&quot;&gt;&lt;/br-brainstorm-card&gt;">
  <br-brainstorm-card description="This is an objective"></br-brainstorm-card>
</br-styleguide-sample>

<br-styleguide-sample
  title="Brainstorm header"
  code="&lt;br-brainstorm-header color=&quot;#45B08B&quot; title=&quot;Growth&quot;&gt;&lt;/br-brainstorm-header&gt;">
  <br-brainstorm-header color="#45B08B" title="Growth"></br-brainstorm-header>
</br-styleguide-sample>
