import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './brainstorm.component.html',
  styles: [
  ]
})
export class DataBrainstormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
