import { Component, OnInit, Input } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  selector: 'br-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {
  @Input() sidePanelOpen?: boolean = false;
  @Input() programName?: string = null;
  
  categories = DataHandler.getData('roadmap/programs/categories')
  programs = DataHandler.getData('roadmap/programs/programs')
  detailItems = DataHandler.getData('roadmap/programs/detailItems')

  constructor() { }

  ngOnInit(): void {
  }

  public getDetailItems(programName) {
    return this.detailItems.filter(item => item.program == programName)   
  }

}
