import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataHandler } from 'src/data/handler'

@Component({
  selector: 'br-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

  private sub: any;

  projects = []
  client = ''

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
       this.client = params['client']
       this.client = this.client == ':client' ? 'Default' : this.client
       this.projects = DataHandler.getAdminData('projects').filter(project => project.client == this.client)
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public changeContext(event, context) {
    DataHandler.setContext(context)
    this.reload()
  }

  reload(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['projects-objectives/projects']);
  }

}
