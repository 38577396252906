import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  selector: 'br-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  nodes = DataHandler.getData('reports/nodes')

  constructor() { }

  ngOnInit(): void {
  }

}
