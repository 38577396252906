import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-brainstorm-card',
  templateUrl: './brainstorm-card.component.html',
  host: {'class': 'c-brainstorm-card',  '[class.c-brainstorm-card--data]': 'type == "data-gathering"', '[class.c-brainstorm-card--scoring]': 'type == "scoring"', '[class.c-brainstorm-card--program]': 'type == "program"' }
})
export class BrainstormCardComponent implements OnInit {
  @Input() subTitle?: string = 'Objective';
  @Input() description?: string;
  @Input() hover?: boolean = false;
  @Input() edit?: boolean = false;
  @Input() type?: string = 'default';
  @Input() score?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
