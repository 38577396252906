<div ngbDropdown class="d-inline-block">
  <div class="c-user-menu" id="dropdown-context-menu" ngbDropdownToggle>
    <h3 class="bc-toolbar__title" style="cursor: default;">{{context}}</h3>
    <br-icon name="caret-down" style="cursor: pointer;"></br-icon>
  </div>

  <div ngbDropdownMenu aria-labelledby="dropdown-context-menu" class="dropdown-menu">
    <div class="dropdown-item" style="cursor: pointer;" (click)="changeContext($event, context)" *ngFor="let context of contexts">{{context.project}}</div>
  </div>
</div>
