import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'br-input',
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
