<div class="row c-roadmap-header sticky-top border-bottom">
    <div class="col-3 h-100 border-right bg-white">
        <h5 class="c-roadmap-header__label">Period 1</h5>
    </div>
    <div class="col-3 h-100 border-right bg-white">
        <h5 class="c-roadmap-header__label">Period 2</h5>
    </div>
    <div class="col-3 h-100 border-right bg-white">
        <h5 class="c-roadmap-header__label">Period 3</h5>
    </div>
    <div class="col-3 h-100 bg-white">
        <h5 class="c-roadmap-header__label">Period 4</h5>
    </div>
</div>

<ng-container *ngFor="let strategy of strategies">
    <div class="row c-roadmap-header c-roadmap-header--collapsable">
        <br-button *ngIf="strategy.collapsed" (click)="strategy.collapsed = !strategy.collapsed" size="small" skin="tertiary" icon="caret-right" layout="icon-only">Toggle</br-button>
        <br-button *ngIf="!strategy.collapsed" (click)="strategy.collapsed = !strategy.collapsed" size="small" skin="tertiary" icon="caret-down" layout="icon-only">Toggle</br-button>
        <br-color-label color="{{strategy.color}}"></br-color-label>
        <h5 class="c-roadmap-header__label">{{strategy.name}}</h5>
    </div>


    <ng-container *ngIf="!strategy.collapsed">
        <ng-container *ngFor="let type of strategy.types">
            <div class="row c-roadmap-header c-roadmap-header--simple">
                <h5 class="c-roadmap-header__label">{{type.type}}</h5>
            </div>
            <div class="row pl-2">
                <div class="col-3 border-right">
                    <ng-container *ngFor="let project of projects">
                        <ng-container *ngIf="project.type == type.type && project.strategy == strategy.name && project.period == 1">
                            <br-roadmap-card (click)="sidePanelOpen=true && projectName=project.project" audience="{{project.audience}}" data="{{project.data}}" skills="{{project.skills}}" processes="{{project.processes}}" name="{{project.project}}" period="{{project.length}}" score="{{project.easiness}}" periodChange="{{project.override}}"></br-roadmap-card>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-3 border-right">
                    <ng-container *ngFor="let project of projects">
                        <ng-container *ngIf="project.type == type.type && project.strategy == strategy.name && project.period == 2">
                            <br-roadmap-card (click)="sidePanelOpen=true && projectName=project.project" audience="{{project.audience}}" data="{{project.data}}" skills="{{project.skills}}" processes="{{project.processes}}" name="{{project.project}}" period="{{project.length}}" score="{{project.easiness}}" periodChange="{{project.override}}"></br-roadmap-card>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-3 border-right">
                    <ng-container *ngFor="let project of projects">
                        <ng-container *ngIf="project.type == type.type && project.strategy == strategy.name && project.period == 3">
                            <br-roadmap-card (click)="sidePanelOpen=true && projectName=project.project" audience="{{project.audience}}" data="{{project.data}}" skills="{{project.skills}}" processes="{{project.processes}}" name="{{project.project}}" period="{{project.length}}" score="{{project.easiness}}" periodChange="{{project.override}}"></br-roadmap-card>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-3 border-right">
                    <ng-container *ngFor="let project of projects">
                        <ng-container *ngIf="project.type == type.type && project.strategy == strategy.name && project.period == 4">
                            <br-roadmap-card (click)="sidePanelOpen=true && projectName=project.project" audience="{{project.audience}}" data="{{project.data}}" skills="{{project.skills}}" processes="{{project.processes}}" name="{{project.project}}" period="{{project.length}}" score="{{project.easiness}}" periodChange="{{project.override}}"></br-roadmap-card>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>



<div class="c-side-panel" *ngIf="sidePanelOpen">
  <div class="c-side-panel__header">
    <div class="d-flex">
      <h3 class="c-side-panel__title">Career path model</h3>
      <br-badge value="5">5</br-badge>
    </div>
    <br-button (click)="sidePanelOpen=false" skin="tertiary" icon="close" layout="icon-only">Close</br-button>
  </div>

  <div class="c-side-panel__body">
    <ul class="list-group list-group-flush">
      <div *ngFor="let category of getDetailItems(projectName)">
        <li class="d-flex align-items-center justify-content-between c-side-panel-collapse-title">
          <div class="d-flex align-items-center">
            <br-button *ngIf="!category.collapsed" (click)="category.collapsed = !category.collapsed" skin="tertiary" icon="caret-down" layout="icon-only">Toggle</br-button>
            <br-button *ngIf="category.collapsed" (click)="category.collapsed = !category.collapsed" skin="tertiary" icon="caret-right" layout="icon-only">Toggle</br-button>
            <strong>{{category.category}}</strong>
          </div>
          <br-badge value="{{category.score}}">{{category.score}}</br-badge>
        </li>
        <ul *ngIf="!category.collapsed" class="list-group list-group-flush">
          <li *ngFor="let item of category.items" class="list-group-item d-flex align-items-center justify-content-between ml-3">
            <span>{{item.item}}</span>
            <br-badge value="{{item.value}}">{{item.value}}</br-badge>
          </li>
        </ul>
      </div>
    </ul>
  </div>
</div>

