<div class="l-sign-in-container-wrapper">
  <div class="l-sign-in-container">
    <div class="l-sign-in-container__inner">
      <h1 class="c-h1 pb-4">Log in</h1>
      <form action="/projects">
        <div class="form-group">
          <label for="email">E-mail address</label>
          <input id="email" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input id="password" type="password" class="form-control">
        </div>
        <div class="mb-3">
          <br-checkbox>Remember password</br-checkbox>
        </div>
        <p>
          <br-button routerLink="/projects-objectives/projects" skin="primary" block="true">Log in</br-button>
        </p>
      </form>
    </div>
  </div>
</div>
