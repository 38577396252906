<br-navbar isFixed="true">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <br-button routerLink="/projects-objectives/projects" skin="tertiary" icon="arrow-left" layout="icon-only">Back</br-button>
      </br-toolbar-item>
      <br-toolbar-item>
        <div class="d-flex flex-column">
          <span class="u-muted c-body-2">Projects &amp; Objectives</span>
          <h3 class="bc-toolbar__title">Project 1</h3>
        </div>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-button skin="primary">Save</br-button>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<div class="compensate-header d-flex flex-column overflow-auto flex-nowrap">
  <div class="container-md mt-5 mb-5">
    <br-card>
      <br-card-header title="Project details"></br-card-header>
      <br-card-body>
        <form>
          <div class="form-group">
            <label for="name">Name</label>
            <input id="name" type="text" class="form-control" value="Project 1">
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea class="form-control" id="description" rows="3">New website</textarea>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Budget</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">€</span>
              </div>
              <input type="text" class="form-control" value="25000" aria-label="Value" aria-describedby="basic-addon1">
            </div>
          </div>
        </form>
      </br-card-body>
    </br-card>

    <br-card>
      <br-card-header title="Objectives project contributes to"></br-card-header>
      <br-card-body>
        <form>
          <div class="form-group d-flex align-items-center">
            <input id="name" type="text" class="form-control mr-2" placeholder="Search objectives...">
            <br-button icon="add" layout="icon-only">Add</br-button>
          </div>
        </form>
        <table class="table">
          <thead>
            <tr class="row">
              <th class="col-sm-6" scope="col">Name</th>
              <th class="col-sm-2" scope="col">Value</th>
              <th class="col-sm-2" scope="col">Contribution</th>
              <th class="col-sm-2" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="row">
              <td class="col-sm-6">Objective A</td>
              <td class="col-sm-2">
                <br-badge value="5">5</br-badge>
              </td>
              <td class="col-sm-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">%</span>
                  </div>
                  <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
                </div>
              </td>
              <td class="col-sm-2">
                <br-button-toolbar>
                  <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
                </br-button-toolbar>
              </td>
            </tr>
            <tr class="row">
              <td class="col-sm-6">Objective B</td>
              <td class="col-sm-2">
                <br-badge value="3">3</br-badge>
              </td>
              <td class="col-sm-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">%</span>
                  </div>
                  <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
                </div>
              </td>
              <td class="col-sm-2">
                <br-button-toolbar>
                  <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
                </br-button-toolbar>
              </td>
            </tr>
          </tbody>
        </table>
      </br-card-body>
    </br-card>

    <br-card>
      <br-card-header title="Info"></br-card-header>
      <br-card-body>
        <form>
          <div class="form-group mb-3 row">
            <label class="mr-2">Business value</label>
            <br-badge value="3">3</br-badge>
          </div>
        </form>
        <form class="form-inline">
          <div class="form-group row">
            <label class="mr-2" for="period">Period</label>
            <span>3</span>
          </div>

          <div class="form-group mx-5 row">
            <label class="mr-2" for="override">Override</label>
            <input id="override" type="text" class="form-control" value="-1">
          </div>
        </form>
      </br-card-body>
    </br-card>
  </div>
</div>
