<br-navbar isFixed="true" level="1">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <br-button routerLink="/data/" skin="tertiary" icon="close" layout="icon-only">Close</br-button>
      </br-toolbar-item>
      <br-toolbar-item>
        <div class="d-flex flex-column">
          <span class="u-muted c-body-2">Data</span>
          <h3 class="bc-toolbar__title">Brainstorm</h3>
        </div>
      </br-toolbar-item>
      <br-toolbar-item>
        <br-tabs>
          <br-tab route="/data/brainstorm/data-gathering">Data gathering</br-tab>
          <br-tab route="/data/brainstorm/scoring">Scoring</br-tab>
          <br-tab route="/data/brainstorm/programs">Programs</br-tab>
        </br-tabs>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <div class="ml-1">
          <br-avatar active="true" image="./assets/person.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar active="true" image="./assets/person-2.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar image="./assets/person-3.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar active="true" image="./assets/person-4.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar image="./assets/person-5.jpg"></br-avatar>
        </div>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<router-outlet></router-outlet>
