import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  @Input() route?: string;
  @Input() active?: boolean = false;

  getClass(active){
    var classList='';
    if(active){
       classList = 'active';
    }else {
        classList = '';
    }
    return classList;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
