<div class="br-styleguide-columns">
  <div class="br-styleguide-columns__column">
    <div class="u-m-2">
      <nav>
        <app-page-tree [routes]="routes" rootPath="/styleguide"></app-page-tree>
      </nav>
    </div>
  </div>
  <div class="br-styleguide-columns__column">
    <div class="br-styleguide-content">
      <div class="u-m-2 u-maximize-height">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
