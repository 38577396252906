<div class="compensate-header">
  <br-navbar isFixed="true">
    <br-toolbar>
      <br-toolbar-group position="left">
        <br-toolbar-item>
          <h3 class="bc-toolbar__title">Data</h3>
        </br-toolbar-item>
        <br-toolbar-item>
          <br-tabs>
            <br-tab route="/data/projects">Projects</br-tab>
            <br-tab route="/data/programs">Programs</br-tab>
            <br-tab route="/data/items">Items</br-tab>
          </br-tabs>
        </br-toolbar-item>
      </br-toolbar-group>
      <br-toolbar-group position="right">
        <br-toolbar-item>
          <br-button routerLink="/data/brainstorm/programs">Go to brainstorm</br-button>
        </br-toolbar-item>
        <br-toolbar-item>
          <div ngbDropdown class="d-inline-block" placement="bottom-right">
            <br-button id="filter-dropdown" ngbDropdownToggle icon="caret-down" layout="icon-right">Filter</br-button>
            <div ngbDropdownMenu aria-labelledby="filter-dropdown" class="p-3">
              <form>
                <div class="form-group">
                  <label>Score</label>
                  <br-checkbox idName="checkbox-1">
                    <br-badge value="1">1</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-2">
                    <br-badge value="2">2</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-3">
                    <br-badge value="3">3</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-4">
                    <br-badge value="4">4</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-5">
                    <br-badge value="5">5</br-badge>
                  </br-checkbox>
                </div>
              </form>
              <div class="dropdown-footer">
                <br-button-toolbar>
                  <br-button-toolbar-item>
                    <br-button size="small">Reset</br-button>
                  </br-button-toolbar-item>
                  <br-button-toolbar-item>
                    <br-button size="small" skin="primary">Filter</br-button>
                  </br-button-toolbar-item>
                </br-button-toolbar>
              </div>
            </div>
          </div>
        </br-toolbar-item>
        <br-toolbar-item>
          <br-button icon="add" skin="primary">Add item</br-button>
        </br-toolbar-item>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>
  <div class="compensate-header overflow-hidden">
    <div class="overflow-auto h-100">
      <table class="table">
        <thead>
          <tr>
            <th class="table-cell-icon" scope="col"></th>
            <th scope="col">Program</th>
            <th scope="col">Data Type</th>
            <th scope="col">Score</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let program of programs">
            <tr>
              <td>
                <br-button *ngIf="!program.collapsed" (click)="program.collapsed = !program.collapsed" skin="tertiary" icon="caret-down" layout="icon-only">Toggle</br-button>
                <br-button *ngIf="program.collapsed" (click)="program.collapsed = !program.collapsed" skin="tertiary" icon="caret-right" layout="icon-only">Toggle</br-button>
              </td>
              <td>{{program.program}}</td>
              <td>{{program.subject}}</td>
              <td>
                <br-badge value="{{program.value}}">{{program.value}}</br-badge>
              </td>
            </tr>
            <tr *ngIf="!program.collapsed" class="c-table__collapse-content">
              <td colspan="99" class="p-0">
                <table>
                  <thead>
                    <tr>
                      <th class="table-cell-icon"></th>
                      <th scope="col">Name</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of items">
                      <tr *ngIf="program.program ==  item.program && program.subject == item.subject">
                        <td></td>
                        <td>{{item.item}}</td>
                        <td>
                          <br-badge value="{{item.value}}">{{item.value}}</br-badge>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
