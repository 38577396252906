import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './data-gathering.component.html',
  styles: [
  ]
})
export class BrainstormDataGatheringComponent implements OnInit {
  currentSection = 'item-0-0';
  prefix = "item-";

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    document.querySelector('#' + section)
      .scrollIntoView();
  }

  strategies = DataHandler.getData('data/brainstorm/data-gathering/strategies')
  items = DataHandler.getData('data/brainstorm/data-gathering/items')

  constructor() { }

  ngOnInit(): void {
  }

}
