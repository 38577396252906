import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  selector: 'br-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  clients = DataHandler.getAdminData('clients')

  constructor() { }

  ngOnInit(): void {
  }

}
