<div class="row h-100 overflow-hidden d-flex flex-nowrap">
  <div class="col-3 border-right bg-white position-relative p-0 h-100">
    <div class="overflow-hidden h-100">
      <div class="overflow-auto h-100">
        <br-navbar isFixed="true">
          <br-toolbar>
            <br-toolbar-group position="left">
              <br-toolbar-item>
                <h3 class="bc-toolbar__title">Items</h3>
              </br-toolbar-item>
            </br-toolbar-group>
            <br-toolbar-group position="right">
              <br-toolbar-item>
                <div ngbDropdown class="d-inline-block" placement="bottom-right">
                  <br-button id="filter-dropdown" ngbDropdownToggle icon="caret-down" layout="icon-right">Filter</br-button>
                  <div ngbDropdownMenu aria-labelledby="filter-dropdown" class="p-3">
                    <form>
                      <div class="form-group">
                        <label>Score</label>
                        <br-checkbox idName="checkbox-1">
                          <br-badge value="1">1</br-badge>
                        </br-checkbox>
                        <br-checkbox idName="checkbox-2">
                          <br-badge value="2">2</br-badge>
                        </br-checkbox>
                        <br-checkbox idName="checkbox-3">
                          <br-badge value="3">3</br-badge>
                        </br-checkbox>
                        <br-checkbox idName="checkbox-4">
                          <br-badge value="4">4</br-badge>
                        </br-checkbox>
                        <br-checkbox idName="checkbox-5">
                          <br-badge value="5">5</br-badge>
                        </br-checkbox>
                      </div>
                    </form>
                    <div class="dropdown-footer">
                      <br-button-toolbar>
                        <br-button-toolbar-item>
                          <br-button size="small">Reset</br-button>
                        </br-button-toolbar-item>
                        <br-button-toolbar-item>
                          <br-button size="small" skin="primary">Filter</br-button>
                        </br-button-toolbar-item>
                      </br-button-toolbar>
                    </div>
                  </div>
                </div>
              </br-toolbar-item>
            </br-toolbar-group>
          </br-toolbar>
        </br-navbar>
        <ul class="m-4" dragula="VAMPIRES">
          <ng-container *ngFor="let item of items">
            <li *ngIf="item.subject == 'Processes' && item.program == 'Unassigned'">
              <div class="mb-2">
                <br-brainstorm-card type="program" score="{{item.score}}" description="{{item.item}}"></br-brainstorm-card>
              </div>
            </li>
          </ng-container>
          </ul>
      </div>
    </div>
  </div>
  <div class="col p-0 h-100">
    <div class="row d-flex h-100 overflow-auto flex-nowrap">
        <ng-container *ngFor="let program of programs">
          <div *ngIf="program.subject == 'Processes'" class="col-4 border-right overflow-auto h-100">
            <br-brainstorm-header type='program' title="{{program.program}}"></br-brainstorm-header>
            <ul dragula="VAMPIRES">
              <ng-container *ngFor="let item of items">
                <li *ngIf="item.subject == 'Processes' && item.program == program.program">
                  <div class="mb-2">
                    <br-brainstorm-card type="program" score="{{item.score}}" description="{{item.item}}"></br-brainstorm-card>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
        <div class="col-4 border-right overflow-auto h-100">
          <div class="mt-4 mb-4">
            <div class="c-blankslate">
              <br-button icon="add">Add program</br-button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
