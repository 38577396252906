<div class="compensate-header">
  <br-navbar isFixed="true">
      <br-toolbar>
          <br-toolbar-group position="left">
              <br-toolbar-item>
                  <br-tabs>
                      <br-tab active route="/data/brainstorm/programs/audience">Audience</br-tab>
                      <br-tab route="/data/brainstorm/programs/skills">Skills</br-tab>
                      <br-tab route="/data/brainstorm/programs/data">Data</br-tab>
                      <br-tab route="/data/brainstorm/programs/processes">Processes</br-tab>
                  </br-tabs>
              </br-toolbar-item>
          </br-toolbar-group>
      </br-toolbar>
  </br-navbar>

  <router-outlet></router-outlet>
</div>
