<div class="row h-100 overflow-hidden">
  <div class="border-right bg-white h-100 position-relative p-0" [ngClass]="isExpanded ? 'col-11' : 'col-3'">
    <button *ngIf="isExpanded" class="btn btn-default btn--icon btn-sm rounded-circle btn--expand" (click)="isExpanded=!isExpanded">
      <br-icon name="caret-left"></br-icon>
    </button>
    <button *ngIf="!isExpanded" class="btn btn-default btn--icon btn-sm rounded-circle btn--expand" (click)="isExpanded=!isExpanded">
      <br-icon name="caret-right"></br-icon>
    </button>

    <div class="overflow-hidden h-100">
      <div class="overflow-auto h-100">
        <div class="m-3 h-100">
          <div class="my-4">
            <h3 class="c-h3">Uncategorised</h3>
            <br-button icon="add">Add item</br-button>
          </div>
          <div class="c-blankslate">
            <h4 class="c-blankslate__title">No objectives yet</h4>
            <p class="u-muted">Add your first item</p>
            <br-button icon="add">Add item</br-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col p-0">
    <div *ngIf="!isExpanded" class="container-fluid overflow-auto container-scroll h-100">
      <div class="mt-4 mb-4 h-100">
        <div class="c-blankslate">
          <h4 class="c-blankslate__title">No strategies yet</h4>
          <p class="u-muted">Add your first column</p>
          <br-button icon="add">Add column</br-button>
        </div>
      </div>
    </div>
  </div>
</div>
