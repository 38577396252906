<ng-container [ngSwitch]="this.type">
  <ng-container *ngSwitchCase="'default'">
    <ng-container *ngIf="!edit">
      <div class="d-flex">
        <div class="c-brainstorm-header__content" (click)="edit=true">
          <span class="c-brainstorm-header__title-overline">Strategy</span>
          <h3 class="c-brainstorm-header__title"><br-color-label color="{{color}}"></br-color-label>{{title}}</h3>
        </div>
        <div class="c-brainstorm-header__buttons">
          <br-button-toolbar vertical="true">
            <br-button-toolbar-item><br-button size="small" icon="edit" layout="icon-only">Edit</br-button></br-button-toolbar-item>
            <br-button-toolbar-item><br-button size="small" dataToggle="modal" dataTarget="#deleteItem" icon="delete" layout="icon-only">Delete</br-button></br-button-toolbar-item>
          </br-button-toolbar>
        </div>
      </div>
      <br-button icon="add">Add card</br-button>
    </ng-container>

    <ng-container *ngIf="edit">
      <div class="c-brainstorm-card__inner--edit">
        <div class="c-brainstorm-card__content">
          <form>
            <div class="form-group">
              <label for="strategy">Strategy</label>
              <input id="strategy" type="text" class="form-control" value="{{title}}">
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <br-button-toolbar>
              <br-button-toolbar-item><br-button (click)="edit=false; hover=false">Cancel</br-button></br-button-toolbar-item>
              <br-button-toolbar-item><br-button skin="primary" (click)="edit=false; hover=false">Save</br-button></br-button-toolbar-item>
            </br-button-toolbar>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'program'">
    <ng-container *ngIf="!edit">
      <div class="d-flex">
        <div class="c-brainstorm-header__content" (click)="edit=true">
          <span class="c-brainstorm-header__title-overline">Program</span>
          <h3 class="c-brainstorm-header__title">{{title}}</h3>
        </div>
        <div class="c-brainstorm-header__buttons">
          <br-button-toolbar vertical="true">
            <br-button-toolbar-item><br-button size="small" icon="edit" layout="icon-only">Edit</br-button></br-button-toolbar-item>
            <br-button-toolbar-item><br-button size="small" dataToggle="modal" dataTarget="#deleteItem" icon="delete" layout="icon-only">Delete</br-button></br-button-toolbar-item>
          </br-button-toolbar>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="edit">
      <div class="c-brainstorm-card__inner--edit">
        <div class="c-brainstorm-card__content">
          <form>
            <div class="form-group">
              <label for="program">Program</label>
              <input id="program" type="text" class="form-control" value="{{title}}">
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <br-button-toolbar>
              <br-button-toolbar-item><br-button (click)="edit=false; hover=false">Cancel</br-button></br-button-toolbar-item>
              <br-button-toolbar-item><br-button skin="primary" (click)="edit=false; hover=false">Save</br-button></br-button-toolbar-item>
            </br-button-toolbar>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
