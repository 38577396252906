import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-radio-button',
  templateUrl: './radio-button.component.html',
  host: {'class': 'custom-control custom-radio'}
})
export class RadioButtonComponent implements OnInit {
  @Input() idName?: string = "radio";
  // @Input() checked?: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
