import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'br-selection-controls',
  templateUrl: './selection-controls.component.html',
  styleUrls: ['./selection-controls.component.scss']
})
export class SelectionControlsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
