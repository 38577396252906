<br-navbar skin="key-700">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item><h3 class="bc-toolbar__title">DS Admin</h3></br-toolbar-item>
      <br-toolbar-item>
        <br-tabs skin="dark">
          <br-tab route="/admin/clients/">Clients</br-tab>
          <br-tab route="/.">Users</br-tab>
        </br-tabs>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-user-menu name="John Doe" company="Innacco" image="./assets/person.jpg"></br-user-menu>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>
<router-outlet></router-outlet>
