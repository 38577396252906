<br-component-page-intro
  title="Cards"
>
  <p>This page documents the card component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Card body"
  code="
  &lt;br-card&gt;
    &lt;br-card-body&gt;
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    &lt;/br-card-body&gt;
  &lt;/br-card&gt;
  ">
  <br-card>
    <br-card-body>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    </br-card-body>
  </br-card>
</br-styleguide-sample>

<br-styleguide-sample
  title="Card header"
  code="
  &lt;br-card&gt;
    &lt;br-card-header title=&quot;Card Header&quot;&gt;&lt;/br-card-header&gt;
    &lt;br-card-body&gt;
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    &lt;/br-card-body&gt;
  &lt;/br-card&gt;
  ">
  <br-card>
    <br-card-header title="Card Header"></br-card-header>
    <br-card-body>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    </br-card-body>
  </br-card>
</br-styleguide-sample>

<br-styleguide-sample
  title="Card with double body"
  code="
  &lt;br-card&gt;
    &lt;br-card-header title=&quot;Card Header&quot;&gt;&lt;/br-card-header&gt;
    &lt;br-card-body&gt;
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    &lt;/br-card-body&gt;
    &lt;br-card-body&gt;
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    &lt;/br-card-body&gt;
  &lt;/br-card&gt;
  ">
  <br-card>
    <br-card-header title="Card Header"></br-card-header>
    <br-card-body>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    </br-card-body>
    <br-card-body>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate, temporibus magnam? Optio harum nam totam voluptatum laborum quasi temporibus corrupti accusamus doloremque dignissimos, ea, officiis, vel recusandae soluta sint mollitia?
    </br-card-body>
  </br-card>
</br-styleguide-sample>

