import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './color-label.component.html',
  styleUrls: ['./color-label.component.scss']
})
export class ColorLabelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
