import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() nodes?: String;

  constructor() { }

  ngOnInit(): void {
  }

}
