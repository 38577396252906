import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-side-panel',
  templateUrl: './side-panel.component.html',
  host: {'class': 'c-side-panel'}
})
export class SidePanelComponent implements OnInit {
  @Input() title?: string;
  constructor() { }

  ngOnInit(): void {
  }

}
