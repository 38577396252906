<br-component-page-intro
  title="Button skins"
>
  <p>This page documents the different button skins.</p>
</br-component-page-intro>

<br-styleguide-sample
  title="Default"
  code="&lt;br-button&gt;Button label&lt;/br-button&gt;">
  <br-button>Button label</br-button>
</br-styleguide-sample>

<br-styleguide-sample
  title="Primary"
  code="&lt;br-button skin=&quot;primary&quot;&gt;Button label&lt;/br-button&gt;">
  <br-button skin="primary">Button label</br-button>
</br-styleguide-sample>

<h4>Parameters</h4>

<table class="table table-striped">
  <thead>
    <th>Name</th>
    <th>value</th>
  </thead>
  <tbody>
    <tr>
      <td>skin</td>
      <td>primary</td>
    </tr>
  </tbody>
</table>