import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  selector: 'br-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  projectsObjectives = DataHandler.getData('projects-objectives/projects/projectsObjectives')
  projects = DataHandler.getData('projects-objectives/projects/projects')

  constructor() { }

  ngOnInit(): void {
  }

}
