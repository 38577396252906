<br-navbar isFixed="true">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <br-button routerLink="/projects-objectives/projects" skin="tertiary" icon="close" layout="icon-only">Close</br-button>
      </br-toolbar-item>
      <br-toolbar-item>
        <div class="d-flex flex-column">
          <span class="u-muted c-body-2">Projects &amp; Objectives</span>
          <h3 class="bc-toolbar__title">Brainstorm</h3>
        </div>
      </br-toolbar-item>
      <br-toolbar-item>
        <br-tabs>
          <br-tab route="/projects-objectives/brainstorm/projects">Projects</br-tab>
          <br-tab route="/projects-objectives/brainstorm/objectives">Objectives</br-tab>
        </br-tabs>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <div class="ml-1">
          <br-avatar active="true" image="./assets/person.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar active="true" image="./assets/person-2.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar image="./assets/person-3.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar active="true" image="./assets/person-4.jpg"></br-avatar>
        </div>
        <div class="ml-1">
          <br-avatar image="./assets/person-5.jpg"></br-avatar>
        </div>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<router-outlet></router-outlet>


<br-modal idName="deleteItem">
  <br-modal-header>Delete item</br-modal-header>
  <br-modal-body>
    <p>Are you sure you want to delete this item?</p>
  </br-modal-body>
  <br-modal-footer>
    <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancel</button>
    <button type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Close">
      <br-icon name="delete"></br-icon>
      <div class="btn__label">Delete</div>
    </button>
  </br-modal-footer>
</br-modal>


<br-modal size="large" *ngIf="hasRoute('brainstorm/objectives')" idName="editItem">
  <br-modal-header>Edit objective</br-modal-header>
  <br-modal-body>
    <form class="mb-5">
      <div class="form-group">
        <label for="name">Name</label>
        <input id="name" type="text" class="form-control" value="Objective A">
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <textarea class="form-control" id="description" rows="3">Increase sales within Europe in the short term</textarea>
      </div>
      <div class="form-group">
        <label for="select">Strategy</label>
        <select class="form-control" id="select" value="Growth">
          <option>Growth</option>
          <option>Digital transfer</option>
          <option>Career coach</option>
        </select>
      </div>
      <div class="form-group">
        <label class="d-block" for="description">Objective value</label>
        <br-scoring></br-scoring>
      </div>
    </form>
    <form>
      <div class="form-group d-flex align-items-center">
        <input id="name" type="text" class="form-control mr-2" placeholder="Search projects...">
        <br-button icon="add" layout="icon-only">Add</br-button>
      </div>
    </form>
    <table class="table">
      <thead>
        <tr class="row">
          <th class="col-sm-6" scope="col">Name</th>
          <th class="col-sm-2" scope="col">Value</th>
          <th class="col-sm-2" scope="col">Contribution</th>
          <th class="col-sm-2" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="row">
          <td class="col-sm-6">Project 1</td>
          <td class="col-sm-2">
            <br-badge value="5">5</br-badge>
          </td>
          <td class="col-sm-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">%</span>
              </div>
              <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
            </div>
          </td>
          <td class="col-sm-2">
            <br-button-toolbar>
              <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
            </br-button-toolbar>
          </td>
        </tr>
        <tr class="row">
          <td class="col-sm-6">Project 5</td>
          <td class="col-sm-2">
            <br-badge value="3">3</br-badge>
          </td>
          <td class="col-sm-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">%</span>
              </div>
              <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
            </div>
          </td>
          <td class="col-sm-2">
            <br-button-toolbar>
              <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
            </br-button-toolbar>
          </td>
        </tr>
      </tbody>
    </table>
  </br-modal-body>
  <br-modal-footer>
    <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancel</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">Save</button>
  </br-modal-footer>
</br-modal>

<br-modal size="large" *ngIf="hasRoute('brainstorm/projects')" idName="editItem">
  <br-modal-header>Edit project</br-modal-header>
  <br-modal-body>
    <form class="mb-5">
      <div class="form-group">
        <label for="name">Name</label>
        <input id="name" type="text" class="form-control" value="Objective A">
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <textarea class="form-control" id="description" rows="3">Increase sales within Europe in the short term</textarea>
      </div>
      <div class="form-group">
        <label for="budget">Budget</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">€</span>
          </div>
          <input type="text" id="budget" class="form-control" value="25000" aria-label="Value" aria-describedby="basic-addon1">
        </div>
      </div>
    </form>
    <form>
      <div class="form-group d-flex align-items-center">
        <input id="name" type="text" class="form-control mr-2" placeholder="Search projects...">
        <br-button icon="add" layout="icon-only">Add</br-button>
      </div>
    </form>
    <table class="table">
      <thead>
        <tr class="row">
          <th class="col-sm-6" scope="col">Name</th>
          <th class="col-sm-2" scope="col">Value</th>
          <th class="col-sm-2" scope="col">Contribution</th>
          <th class="col-sm-2" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="row">
          <td class="col-sm-6">Project 1</td>
          <td class="col-sm-2">
            <br-badge value="5">5</br-badge>
          </td>
          <td class="col-sm-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">%</span>
              </div>
              <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
            </div>
          </td>
          <td class="col-sm-2">
            <br-button-toolbar>
              <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
            </br-button-toolbar>
          </td>
        </tr>
        <tr class="row">
          <td class="col-sm-6">Project 5</td>
          <td class="col-sm-2">
            <br-badge value="3">3</br-badge>
          </td>
          <td class="col-sm-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">%</span>
              </div>
              <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
            </div>
          </td>
          <td class="col-sm-2">
            <br-button-toolbar>
              <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
            </br-button-toolbar>
          </td>
        </tr>
      </tbody>
    </table>
  </br-modal-body>
  <br-modal-footer>
    <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancel</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">Save</button>
  </br-modal-footer>
</br-modal>
