<div class="alert alert-{{this.skin}}"
  role="alert">
  <div class="alert__content">
    <ng-container [ngSwitch]="this.skin">
      <br-icon *ngSwitchCase="'success'" name="circle-check"></br-icon>
      <br-icon *ngSwitchCase="'danger'" name="warning"></br-icon>
      <br-icon *ngSwitchDefault name="circle-info"></br-icon>
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>
