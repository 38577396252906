import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './projects.component.html',
  styles: [
  ]
})
export class DataProjectsComponent implements OnInit {
  optionValue;
  selectedView: string = 'Items';

  //event handler for the select element's change event
  selectChangeHandler(event: any) {
    //update the ui
    this.selectedView = event.target.value;
  }

  programItems = DataHandler.getData('data/projects/programItems')
  projects = DataHandler.getData('data/projects/projects')
  audience = DataHandler.getData('data/projects/audience')
  skills = DataHandler.getData('data/projects/skills')
  data = DataHandler.getData('data/projects/data')
  processes = DataHandler.getData('data/projects/processes')

  constructor() { }

  ngOnInit(): void {
  }

}
