<br-component-page-intro
  title="Roadmap card"
>
  <p>This page documents the roadmap card component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Card"
  code="
    &lt;br-roadmap-card audience=&quot;1&quot; data=&quot;2&quot; skills=&quot;2&quot; processes=&quot;5&quot; name=&quot;Project A&quot; period=&quot;-1&quot; color=&quot;#E9B135&quot;&gt;&lt;/br-roadmap-card&gt;
  ">
  <br-roadmap-card audience="1" data="2" skills="2" processes="5" name="Project A" periodChange="-1" score="5" period="1"></br-roadmap-card>
</br-styleguide-sample>
