import { Component, OnInit, Input } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './projects.component.html',
  styles: [
  ]
})
export class BrainstormProjectsComponent implements OnInit {
  @Input() isExpanded?: boolean = false;
  constructor() { }

  strategies = DataHandler.getData('projects-objectives/brainstorm/projects/strategies')
  projects = DataHandler.getData('projects-objectives/brainstorm/projects/projects')
  
  ngOnInit(): void {
  }

}
