<br-component-page-intro title="Navbar"></br-component-page-intro>

<br-styleguide-sample
  title="Default"
  code="
    &lt;br-navbar&gt;
      &lt;br-toolbar&gt;
        &lt;br-toolbar-group position=&quot;left&quot;&gt;
          &lt;br-toolbar-item&gt;&lt;h3 class=&quot;bc-toolbar__title&quot;&gt;Title&lt;/h3&gt;&lt;/br-toolbar-item&gt;
        &lt;/br-toolbar-group&gt;
      &lt;/br-toolbar&gt;
    &lt;/br-navbar&gt;
  ">
  <br-navbar>
    <br-toolbar>
      <br-toolbar-group position="left">
        <br-toolbar-item><h3 class="bc-toolbar__title">Title</h3></br-toolbar-item>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>
</br-styleguide-sample>

<br-styleguide-sample
  title="Dark"
  code="
  &lt;br-navbar skin=&quot;key-700&quot;&gt;
    &lt;br-toolbar&gt;
      &lt;br-toolbar-group position=&quot;left&quot;&gt;
        &lt;br-toolbar-item&gt;&lt;h3 class=&quot;bc-toolbar__title&quot;&gt;Title&lt;/h3&gt;&lt;/br-toolbar-item&gt;
      &lt;/br-toolbar-group&gt;
    &lt;/br-toolbar&gt;
  &lt;/br-navbar&gt;
  ">
  <br-navbar skin="key-700">
    <br-toolbar>
      <br-toolbar-group position="left">
        <br-toolbar-item><h3 class="bc-toolbar__title">Title</h3></br-toolbar-item>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>
</br-styleguide-sample>
