<button class="btn btn-{{this.skin}}" [ngClass]="getClass(this.layout, this.block, this.size)" attr.data-toggle="{{dataToggle}}" attr.data-target="{{dataTarget}}" >
  <ng-container [ngSwitch]="this.layout" *ngIf="this.icon !== undefined ; else default">
    <ng-container *ngSwitchCase="'icon-only'">
      <br-icon name="{{this.icon}}"></br-icon>
      <span class="u-sr-only">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="'icon-right'">
      <div class="btn__label">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      <br-icon name="{{this.icon}}"></br-icon>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <br-icon name="{{this.icon}}"></br-icon>
      <div class="btn__label">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #default>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-template>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</button>
