import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'br-roadmap-card',
  templateUrl: './roadmap-card.component.html',
  host: { '[class]': 'hostClass + " " + colorClass', '[class.focus]': 'focus == true' }
})
export class RoadmapCardComponent implements OnInit {
  @Input() name?: string;
  @Input() audience?: string;
  @Input() data?: string;
  @Input() skills?: string;
  @Input() processes?: string;
  @Input() periodChange?: string;
  @Input() score?: number;
  @Input() period?: string;

  constructor() { }

  get hostClass(): string {
    if (this.period === '1') {
      return 'c-roadmap-card c-roadmap-card--1';
    } else if (this.period === '2') {
      return 'c-roadmap-card c-roadmap-card--2';
    } else if (this.period === '3') {
      return 'c-roadmap-card c-roadmap-card--3';
    } else if (this.period === '4') {
      return 'c-roadmap-card c-roadmap-card--4';
    } else {
      return 'c-roadmap-card';
    }
  }
  get colorClass(): string {
    if (this.score == 1) {
      return 'c-roadmap-card--error';
    } else if (this.score == 2) {
      return 'c-roadmap-card--md-error';
    } else if (this.score == 3) {
      return 'c-roadmap-card--warning';
    } else if (this.score == 4) {
      return 'c-roadmap-card--md-success';
    } else if (this.score == 5) {
      return 'c-roadmap-card--success';
    } else {
      return 'c-roadmap-card--warning';
    }
  }

  ngOnInit(): void {
  }

}
