<br-navbar skin="key-700" isFixed="true" level="1">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <br-context-menu></br-context-menu>
      </br-toolbar-item>
      <br-toolbar-item>
        <br-tabs skin="dark">
          <br-tab route="/projects-objectives">Projects &amp; Objectives</br-tab>
          <br-tab route="/data">Data</br-tab>
          <br-tab route="/reports">Reports</br-tab>
          <br-tab route="/roadmap">Roadmap</br-tab>
        </br-tabs>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-user-menu name="John Doe" company="Innacco" image="./assets/person.jpg"></br-user-menu>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<div class="compensate-header">
  <br-navbar isFixed="true" level="2">
    <br-toolbar>
      <br-toolbar-group position="left">
        <br-toolbar-item><h3 class="bc-toolbar__title">Roadmap</h3></br-toolbar-item>
        <br-toolbar-item>
          <br-tabs>
            <br-tab route="/roadmap/projects">Projects</br-tab>
            <br-tab route="/roadmap/programs">Programs</br-tab>
          </br-tabs>
        </br-toolbar-item>
      </br-toolbar-group>
      <br-toolbar-group position="right">
        <br-toolbar-item>
          <div ngbDropdown class="d-inline-block" placement="bottom-right">
            <br-button id="filter-dropdown" ngbDropdownToggle icon="caret-down" layout="icon-right">Filter</br-button>
            <div ngbDropdownMenu aria-labelledby="filter-dropdown" class="p-3">
              <form>
                <div class="form-group">
                  <label>Strategy</label>
                  <br-checkbox idName="checkbox-growth" color="#73C3B5">Growth</br-checkbox>
                  <br-checkbox idName="checkbox-digital-transfer" color="#9966CC">Digital transfer</br-checkbox>
                  <br-checkbox idName="checkbox-climate-awareness" color="#A2194A">Climate awareness</br-checkbox>
                </div>

                <hr/>

                <div class="form-group" *ngIf="router.url ==='/roadmap/projects'">
                  <label>Project type</label>
                  <br-checkbox idName="checkbox-new">New</br-checkbox>
                  <br-checkbox idName="checkbox-rd">R&amp;D</br-checkbox>
                  <br-checkbox idName="checkbox-business">Business</br-checkbox>
                </div>

                <div class="form-group" *ngIf="router.url ==='/roadmap/programs'">
                  <label>Data type</label>
                  <br-checkbox idName="checkbox-audience">Audience</br-checkbox>
                  <br-checkbox idName="checkbox-skills">Skills</br-checkbox>
                  <br-checkbox idName="checkbox-data">Data</br-checkbox>
                  <br-checkbox idName="checkbox-processes">Processes</br-checkbox>
                </div>

                <hr/>

                <div class="form-group">
                  <label>Score</label>
                  <br-checkbox idName="checkbox-1"><br-badge value="1">1</br-badge></br-checkbox>
                  <br-checkbox idName="checkbox-2"><br-badge value="2">2</br-badge></br-checkbox>
                  <br-checkbox idName="checkbox-3"><br-badge value="3">3</br-badge></br-checkbox>
                  <br-checkbox idName="checkbox-4"><br-badge value="4">4</br-badge></br-checkbox>
                  <br-checkbox idName="checkbox-5"><br-badge value="5">5</br-badge></br-checkbox>
                </div>
              </form>
              <div class="dropdown-footer">
                <br-button-toolbar>
                  <br-button-toolbar-item><br-button size="small">Reset</br-button></br-button-toolbar-item>
                  <br-button-toolbar-item><br-button size="small" skin="primary">Filter</br-button></br-button-toolbar-item>
                </br-button-toolbar>
              </div>
            </div>
          </div>
        </br-toolbar-item>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>

  <div class="compensate-header d-flex flex-column overflow-auto flex-nowrap">
    <router-outlet></router-outlet>
  </div>
</div>
