<div class="row h-100 overflow-hidden">
  <div class="border-right bg-white h-100 position-relative p-0" [ngClass]="isExpanded ? 'col-11' : 'col-3'">
    <button *ngIf="isExpanded" class="btn btn-default btn--icon btn-sm rounded-circle btn--expand" (click)="isExpanded=!isExpanded">
      <br-icon name="caret-left"></br-icon>
    </button>
    <button *ngIf="!isExpanded" class="btn btn-default btn--icon btn-sm rounded-circle btn--expand" (click)="isExpanded=!isExpanded">
      <br-icon name="caret-right"></br-icon>
    </button>

    <div class="overflow-hidden h-100">
      <div class="overflow-auto h-100">
        <div class="m-4">
          <h3 class="c-h3">Uncategorised</h3>
          <br-button icon="add">Add item</br-button>
        </div>

        <ul class="row mx-2">
          <ng-container *ngFor="let item of projects">
            <li *ngIf="item.strategy == 'Unassigned'" [ngClass]="isExpanded ? 'col-3' : 'col-12'">
              <div class="mb-2">
                <br-brainstorm-card subTitle="Project" description="{{item.project}}"></br-brainstorm-card>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="col h-100 p-0 border-right">
    <div *ngIf="!isExpanded" class="row d-flex h-100 overflow-auto flex-nowrap">
      <ng-container *ngFor="let strategy of strategies">
        <div class="col-4 border-right overflow-auto h-100">
          <br-brainstorm-header color="{{strategy.color}}" title="{{strategy.name}}"></br-brainstorm-header>
          <ul>
            <ng-container *ngFor="let item of projects">
              <li *ngIf="item.strategy == strategy.name">
                <div class="mb-2">
                  <br-brainstorm-card subTitle="Project" description="{{item.project}}"></br-brainstorm-card>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
