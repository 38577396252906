<ul class="c-tree">
  <li *ngFor="let node of nodes" class="c-tree__parent">
    <div class="c-tree__parent-name">
      <br-button *ngIf="node.children && node.expanded" (click)="node.expanded = !node.expanded" skin="tertiary" icon="caret-down" layout="icon-only">Toggle</br-button>
      <br-button *ngIf="node.children && !node.expanded" (click)="node.expanded = !node.expanded" skin="tertiary" icon="caret-right" layout="icon-only">Toggle</br-button>
      <br-checkbox isChecked="{{node.isChecked}}" color="{{node.color}}" idName="{{node.id}}">{{node.name}}</br-checkbox>
    </div>
    <ul *ngIf="node.children && node.expanded" class="c-tree__children">
      <li *ngFor="let child of node.children">
        <br-checkbox isChecked="{{child.isChecked}}" color="{{child.color}}" idName="{{child.id}}">{{child.name}}</br-checkbox>
      </li>
    </ul>
  </li>
</ul>
