import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-avatar',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnInit {
  @Input() size?: string = "default";
  @Input() image?: string;
  @Input() active?: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
