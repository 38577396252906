<br-component-page-intro
  title="Inputs"
>
  <p>This page documents the input component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Text input"
  code="&lt;input id=&quot;id&quot; type=&quot;text&quot; class=&quot;form-control&quot;&gt;">
  <input id="id" type="text" class="form-control">
</br-styleguide-sample>

<br-styleguide-sample
  title="Select"
  code="
  &lt;form&gt;
    &lt;div class=&quot;form-group&quot;&gt;
      &lt;label for=&quot;exampleFormControlSelect1&quot;&gt;Example select&lt;/label&gt;
      &lt;select class=&quot;form-control&quot; id=&quot;exampleFormControlSelect1&quot;&gt;
        &lt;option&gt;1&lt;/option&gt;
        &lt;option&gt;2&lt;/option&gt;
        &lt;option&gt;3&lt;/option&gt;
        &lt;option&gt;4&lt;/option&gt;
        &lt;option&gt;5&lt;/option&gt;
      &lt;/select&gt;
    &lt;/div&gt;
  &lt;/form&gt;
  ">
  <form>
    <div class="form-group">
      <label for="exampleFormControlSelect1">Example select</label>
      <select class="form-control" id="exampleFormControlSelect1">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </select>
    </div>
  </form>
</br-styleguide-sample>

<br-styleguide-sample
  title="Textarea"
  code="
  &lt;form&gt;
    &lt;div class=&quot;form-group&quot;&gt;
      &lt;label for=&quot;exampleFormControlTextarea1&quot;&gt;Example textarea&lt;/label&gt;
      &lt;textarea class=&quot;form-control&quot; id=&quot;exampleFormControlTextarea1&quot; rows=&quot;3&quot;&gt;&lt;/textarea&gt;
    &lt;/div&gt;
  &lt;/form&gt;
  ">
  <form>
    <div class="form-group">
      <label for="exampleFormControlTextarea1">Example textarea</label>
      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
    </div>
  </form>
</br-styleguide-sample>

<br-styleguide-sample
  title="Input prepend"
  code="
  &lt;form&gt;
    &lt;div class=&quot;form-group&quot;&gt;
      &lt;label for=&quot;exampleFormControlTextarea1&quot;&gt;Example input prepend&lt;/label&gt;
      &lt;div class=&quot;input-group mb-3&quot;&gt;
        &lt;div class=&quot;input-group-prepend&quot;&gt;
          &lt;span class=&quot;input-group-text&quot; id=&quot;basic-addon1&quot;&gt;&lt;br-icon name=&quot;add&quot;&gt;&lt;/br-icon&gt;&lt;/span&gt;
        &lt;/div&gt;
        &lt;input type=&quot;text&quot; class=&quot;form-control&quot; placeholder=&quot;Value&quot; aria-label=&quot;Value&quot; aria-describedby=&quot;basic-addon1&quot;&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/form&gt;
  ">
  <form>
    <div class="form-group">
      <label for="exampleFormControlTextarea1">Example input prepend</label>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"><br-icon name="add"></br-icon></span>
        </div>
        <input type="text" class="form-control" placeholder="Value" aria-label="Value" aria-describedby="basic-addon1">
      </div>
    </div>
  </form>
</br-styleguide-sample>

