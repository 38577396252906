import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'br-button-types',
  templateUrl: './button-types.component.html',
  styleUrls: ['./button-types.component.scss']
})
export class ButtonTypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
