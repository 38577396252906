import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
