<br-navbar>
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <h3 class="bc-toolbar__title">Clients</h3>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addObjective">
          <br-icon name="add"></br-icon>
          <div class="btn-label">Add client</div>
        </button>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<table class="table">
  <thead>
    <tr>
      <th class="pl-4">Client</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let client of clients">
      <td class="pl-4">
        {{client.name}}
      </td>
      <td>
        <br-button-toolbar>
          <br-button [routerLink]="['/admin/clients', client.name]" skin="tertiary" icon="chevron-right" layout="icon-only">To detail</br-button>
        </br-button-toolbar>
      </td>
    </tr>
  </tbody>
</table>
