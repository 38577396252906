<br-navbar level="1">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item><br-button routerLink="/admin/clients" skin="tertiary" icon="arrow-left" layout="icon-only">Back</br-button></br-toolbar-item>
      <br-toolbar-item>
        <div class="d-flex flex-column">
          <span class="u-muted c-body-2">Clients</span>
          <h3 class="bc-toolbar__title">{{client}}</h3>
        </div>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-button skin="primary" icon="add">Add new data strategy project</br-button>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>

<table class="table">
  <thead>
    <tr>
      <th class="pl-4">Data strategy project</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let project of projects">
      <td class="pl-4">
        {{project.project}}
      </td>
      <td>
        <br-button-toolbar>
          <br-button (click)="changeContext($event, project)" skin="tertiary" icon="chevron-right" layout="icon-only">To detail</br-button>
        </br-button-toolbar>
      </td>
    </tr>
  </tbody>
</table>
