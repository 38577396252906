export const colors = [
  {
    category: "Grays",
    items: [
      { name: "$gray-50 ",   hex: " #FAF9FB" },
      { name: "$gray-100 ",   hex: " #EFEFF5" },
      { name: "$gray-150 ",   hex: " #E0E1EB" },
      { name: "$gray-200 ",   hex: " #CCCDDE" },
      { name: "$gray-300 ",   hex: " #B9B9C8" },
      { name: "$gray-400 ",   hex: " #ADAEBF" },
      { name: "$gray-500 ",   hex: " #8D8DA1" },
      { name: "$gray-600 ",   hex: " #5E5F73" },
      { name: "$gray-700 ",   hex: " #353650" },
      { name: "$gray-800 ",   hex: " #161626" },
      { name: "$gray-900 ",   hex: " #0B0B13" },
    ]
  },
  {
    category: "Key colors",
    items: [
      { name: "$key-700", hex:"#1A173B"},
      { name: "$key-500", hex:"#4D42BD"},
      { name: "$key-300", hex:"#E3E2EE"}
    ]
  },
  {
    category: "Semantic",
    items: [
      { name: "$accent", hex:"#EBB847"},
      { name: "$success", hex:"#45B08B"},
      { name: "$error", hex:"#BF4342"},
      { name: "$warning", hex:"#EBB847"},
    ]
  }
];
