import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataHandler } from 'src/data/handler';

@Component({
  selector: 'br-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  contexts = DataHandler.getContexts()
  context = DataHandler.getContexts().find(ctx => ctx.dataset == DataHandler.dataset).project

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public changeContext(event, context) {
    DataHandler.setContext(context)
    this.reload()
  }

  reload(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

}
