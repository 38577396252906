import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() skin?: string = 'default';

  constructor() { }

  ngOnInit(): void {
  }

}
