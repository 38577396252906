<br-component-page-intro
  title="Alerts"
>
  <p>This page documents the alert component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Info"
  code="&lt;br-alert&gt;This is an alert&lt;/br-alert&gt;">
  <br-alert>This is an alert</br-alert>
</br-styleguide-sample>

<br-styleguide-sample
  title="Success"
  code="&lt;br-alert skin=&quot;success&quot;&gt;This is an alert&lt;/br-alert&gt;">
  <br-alert skin="success">This is an alert</br-alert>
</br-styleguide-sample>

<br-styleguide-sample
  title="Danger"
  code="&lt;br-alert skin=&quot;danger&quot;&gt;This is an alert&lt;/br-alert&gt;">
  <br-alert skin="danger">This is an alert</br-alert>
</br-styleguide-sample>
