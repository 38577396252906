import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-checkbox',
  templateUrl: './checkbox.component.html',
  host: {'class': 'custom-control custom-checkbox'}
})
export class CheckboxComponent implements OnInit {
  @Input() idName?: string = "checkbox";
  @Input() color?: string;
  @Input() isChecked?: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
