import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'br-card',
  templateUrl: './card.component.html',
  host: {'class': 'card'}
})
export class CardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
