<div class="btn-group btn-group-toggle btn-group-toggle--scoring" data-toggle="buttons">
  <label class="btn btn-secondary rounded-circle">
    <input type="radio" name="options" id="option1"> 1
  </label>
  <label class="btn btn-secondary rounded-circle">
    <input type="radio" name="options" id="option2"> 2
  </label>
  <label class="btn btn-secondary rounded-circle">
    <input type="radio" name="options" id="option3"> 3
  </label>
  <label class="btn btn-secondary rounded-circle">
    <input type="radio" name="options" id="option4"> 4
  </label>
  <label class="btn btn-secondary rounded-circle">
    <input type="radio" name="options" id="option5"> 5
  </label>

</div>
