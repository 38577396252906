import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './programs.component.html',
  styles: [
  ]
})
export class DataProgramsComponent implements OnInit {

  items = DataHandler.getData('data/programs/items')
  programs = DataHandler.getData('data/programs/programs')

  constructor() { }

  ngOnInit(): void {
  }

}
