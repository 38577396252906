<div class="l-sign-in-container-wrapper">
  <div class="l-sign-in-container">
    <div class="l-sign-in-container__inner">
      <h1 class="c-h1 pb-4">Create account</h1>
      <form action="/app/dashboard">
        <div class="form-group">
          <label for="name">Name</label>
          <input id="name" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label for="email">E-mail address</label>
          <input id="email" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input id="password" type="password" class="form-control">
        </div>
        <div class="form-group">
          <label for="repeatPassword">Repeat password</label>
          <input id="repeatPassword" type="password" class="form-control">
        </div>
        <p>
          <br-button routerLink="/projects-objectives/projects" skin="primary" block="true">Create account</br-button>
        </p>
      </form>
    </div>
  </div>
</div>
