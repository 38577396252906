import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-scoring',
  templateUrl: './scoring.component.html',
  styles: [
  ]
})
export class ScoringComponent implements OnInit {
  @Input() activeItem?: string = '0';

  constructor() { }

  ngOnInit(): void {
  }

}
