import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() skin?: string = 'default';
  @Input() isFixed?: boolean = false;
  @Input() border?: string = 'bottom';
  @Input() level?: string;

  getLevelClass(level) {
    if(level !== undefined) {
      return 'navbar--level-' + level;
    } else {
      return ''
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
