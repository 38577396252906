import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  @Input() name?: string;
  @Input() initials?: string;
  @Input() company?: string;
  @Input() image?: string;
  constructor() { }

  ngOnInit(): void {
  }

}
