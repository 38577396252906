<ul class="br-styleguide-bordered-list">
  <li *ngFor="let page of pages">
    <ng-container *ngTemplateOutlet="pageTree;context:{page: page}"></ng-container>
  </li>
</ul>

<ng-template #pageTree let-page="page">
  <a [routerLink]="page.path" *ngIf="!page.isCategory" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{page.name}}</a>
  <h4 *ngIf="page.isCategory" class="br-styleguide-bordered-list-header">{{page.name}}</h4>
  <ul *ngIf="page.children.length > 0" class="br-styleguide-bordered-list--is-child br-styleguide-bordered-list">
    <ng-container *ngFor="let child of page.children">
      <li><ng-container *ngTemplateOutlet="pageTree;context:{page: child}"></ng-container></li>
    </ng-container>
  </ul>
</ng-template>
