<div [className]="!audience || !data || !skills || !processes ? 'c-roadmap-card__header c-roadmap-card__header--big' : 'c-roadmap-card__header'">
  <h3 class="c-roadmap-card__title">{{name}}</h3>
  <div *ngIf="periodChange" class="c-roadmap-card__period" placement="bottom" ngbTooltip="Period override">
    <br-icon name="time-line"></br-icon>
    <span >{{periodChange}}</span>
  </div>
</div>
<div class="c-roadmap-card__body" *ngIf="audience || data || skills || processes">
  <div placement="bottom" ngbTooltip="Audience">
    <br-badge value="{{audience}}">{{audience}}</br-badge>
  </div>

  <div placement="bottom" ngbTooltip="Data">
    <br-badge value="{{data}}">{{data}}</br-badge>
  </div>

  <div placement="bottom" ngbTooltip="Skills">
    <br-badge value="{{skills}}">{{skills}}</br-badge>
  </div>

  <div placement="bottom" ngbTooltip="Processes">
    <br-badge value="{{processes}}">{{processes}}</br-badge>
  </div>
</div>
