<br-component-page-intro
  title="Colors"
>
  <p>This page documents the different colors.</p>
</br-component-page-intro>

<ng-container *ngFor="let color of colors">
  <h2 class="c-h3">{{ color.category}}</h2>
  <ul class="br-styleguide-color-swatch-list">
    <li *ngFor="let item of color.items" class="br-styleguide-color-swatch">
      <div class="br-styleguide-color-swatch__self" style="background:{{item.hex}}"></div>
      <span class="br-styleguide-color-swatch__line1">{{item.name}}<br></span>
      <span class="br-styleguide-color-swatch__line2">{{item.hex}}</span>
    </li>
  </ul>
</ng-container>
