<br-component-page-intro
  title="Table"
>
  <p>This page documents the table component.</p>
</br-component-page-intro>

<br-styleguide-sample
  title="Default"
  code="
  &lt;table class=&quot;table&quot;&gt;
    &lt;thead&gt;
      &lt;tr&gt;
        &lt;th scope=&quot;col&quot;&gt;Header&lt;/th&gt;
        &lt;th scope=&quot;col&quot;&gt;Header&lt;/th&gt;
        &lt;th scope=&quot;col&quot;&gt;Header&lt;/th&gt;
        &lt;th scope=&quot;col&quot;&gt;Header&lt;/th&gt;
      &lt;/tr&gt;
    &lt;/thead&gt;
    &lt;tbody&gt;
      &lt;tr *ngFor=&quot;let number of [0,1,2]&quot;&gt;
        &lt;td&gt;Data point&lt;/td&gt;
        &lt;td&gt;Data point&lt;/td&gt;
        &lt;td&gt;Data point&lt;/td&gt;
        &lt;td&gt;Data point&lt;/td&gt;
      &lt;/tr&gt;
    &lt;/tbody&gt;
  &lt;/table&gt;
  ">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Header</th>
        <th scope="col">Header</th>
        <th scope="col">Header</th>
        <th scope="col">Header</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let number of [0,1,2]">
        <td>Data point</td>
        <td>Data point</td>
        <td>Data point</td>
        <td>Data point</td>
      </tr>
    </tbody>
  </table>
</br-styleguide-sample>

<br-styleguide-sample
  title="Data table with action"
  code="
  &lt;table class=&quot;table&quot;&gt;
    &lt;thead&gt;
      &lt;tr&gt;
        &lt;th scope=&quot;col&quot;&gt;Header&lt;/th&gt;
        &lt;th scope=&quot;col&quot;&gt;Header&lt;/th&gt;
        &lt;th scope=&quot;col&quot;&gt;Header&lt;/th&gt;
        &lt;th scope=&quot;col&quot;&gt;&lt;/th&gt;
      &lt;/tr&gt;
    &lt;/thead&gt;
    &lt;tbody&gt;
      &lt;tr *ngFor=&quot;let number of [0,1,2]&quot;&gt;
        &lt;td&gt;Data point&lt;/td&gt;
        &lt;td&gt;Data point&lt;/td&gt;
        &lt;td&gt;Data point&lt;/td&gt;
        &lt;td&gt;
          &lt;br-button-toolbar&gt;
            &lt;br-button skin=&quot;tertiary&quot; icon=&quot;chevron-right&quot; layout=&quot;icon-only&quot;&gt;To detail&lt;/br-button&gt;
          &lt;/br-button-toolbar&gt;
        &lt;/td&gt;
      &lt;/tr&gt;
    &lt;/tbody&gt;
  &lt;/table&gt;
  ">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Header</th>
        <th scope="col">Header</th>
        <th scope="col">Header</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let number of [0,1,2]">
        <td>Data point</td>
        <td>Data point</td>
        <td>Data point</td>
        <td>
          <br-button-toolbar>
            <br-button skin="tertiary" icon="chevron-right" layout="icon-only">To detail</br-button>
          </br-button-toolbar>
        </td>
      </tr>
    </tbody>
  </table>
</br-styleguide-sample>

