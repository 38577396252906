<br-component-page-intro
  title="Color label"
>
  <p>This page documents the color label component.</p>
</br-component-page-intro>

<br-styleguide-sample
  title="Default"
  code="
  &lt;div class=&quot;d-flex&quot;&gt;
    &lt;br-color-label color=&quot;#B172EF&quot;&gt;&lt;/br-color-label&gt;
    &lt;br-color-label color=&quot;#EBB847&quot;&gt;&lt;/br-color-label&gt;
    &lt;br-color-label color=&quot;#C5C53D&quot;&gt;&lt;/br-color-label&gt;
    &lt;br-color-label color=&quot;#47AE8A&quot;&gt;&lt;/br-color-label&gt;
  &lt;/div&gt;
  ">
  <div class="d-flex">
    <br-color-label color="#B172EF"></br-color-label>
    <br-color-label color="#EBB847"></br-color-label>
    <br-color-label color="#C5C53D"></br-color-label>
    <br-color-label color="#47AE8A"></br-color-label>
  </div>
</br-styleguide-sample>
