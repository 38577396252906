import { Component, OnInit, Input } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './objectives-default.component.html',
  styles: [
  ]
})
export class BrainstormObjectivesDefaultComponent implements OnInit {
  @Input() isExpanded?: boolean = false;
  constructor() { }

  strategies = DataHandler.getData('projects-objectives/brainstorm/objectives/objectives-default/strategies')
  objectives = DataHandler.getData('projects-objectives/brainstorm/objectives/objectives-default/objectives')

  ngOnInit(): void {
  }

}
