import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './data.component.html',
  styles: [
  ]
})
export class BrainstormProgramsDataComponent implements OnInit {
  
  programs = DataHandler.getData('data/brainstorm/programs/data/programs')
  items = DataHandler.getData('data/brainstorm/programs/data/items')

  constructor() { }

  ngOnInit(): void {
  }

}
