<div class="c-side-panel__header">
  <div class="d-flex">
    <h3 class="c-side-panel__title">{{title}}</h3>
    <br-badge value="5">5</br-badge>
  </div>
  <br-button skin="tertiary" icon="close" layout="icon-only">Close</br-button>
</div>

<div class="c-side-panel__body">
  <ng-content></ng-content>
</div>
