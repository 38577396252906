<br-component-page-intro
  title="Blankslate"
>
  <p>This page documents the blankslate component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Default"
  code="
    &lt;div class=&quot;c-blankslate&quot;&gt;
        &lt;h4 class=&quot;c-blankslate__title&quot;&gt;No items yet&lt;/h4&gt;
        &lt;p&gt;Add your first item&lt;/p&gt;
        &lt;br-button icon=&quot;add&quot;&gt;Add item&lt;/br-button&gt;
    &lt;/div&gt;
  ">
  <div class="c-blankslate">
      <h4 class="c-blankslate__title">No items yet</h4>
      <p>Add your first item</p>
      <br-button icon="add">Add item</br-button>
  </div>
</br-styleguide-sample>

