export const DataHandler = {

  dataset: window.localStorage.getItem('context') ?? 'default',
  client: window.localStorage.getItem('client') ?? 'Default',

  setContext(context) {
    console.log(`Changing dataset to ${context.dataset}...`)
    this.dataset = context.dataset
    this.client = context.client
    window.localStorage.setItem('context', context.dataset)
    window.localStorage.setItem('client', context.client)
  },

  getContexts() {
    return this.getAdminData('projects').filter(project => project.client == this.client)
  },

  getAdminData(objectName: string) {
    try {
      const data = require(`./admin.json`)
      
      return data[objectName]
    } catch (error) {
      console.error(`Object ${objectName} not found in admin file!`)
    }
  },

  getData(path: string, dataset?: string) {

    if (path.charAt(0) === '/') path = path.substring(1)
    const objectName = path.split('/')[path.split('/').length - 1]
    const filename = path.replace(new RegExp(`/${objectName}` + '$'), '')

    const ctx = dataset ?? this.dataset

    try {
      const data = require(`./${ctx}/${filename}.json`)
      
      try {
        const object = data[objectName]

        if (Object.keys(object).length > 0) return object
      } catch (error) {

        console.error(`Object ${objectName} not found!`)
      }

    } catch (error) {
      console.error(`File ${filename} not found in context ${ctx}!`)
    }
  },

}