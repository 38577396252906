<br-component-page-intro
  title="Avatars"
>
  <p>This page documents the avatar component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Default avatar"
  code="&lt;br-avatar&gt;JD&lt;/br-avatar&gt;">
  <br-avatar>JD</br-avatar>
</br-styleguide-sample>

<br-styleguide-sample
  title="Avatar with image"
  code="&lt;br-avatar image=&quot;./assets/person.jpg&quot;&gt;&lt;/br-avatar&gt;">
  <br-avatar image="./assets/person.jpg"></br-avatar>
</br-styleguide-sample>

<br-styleguide-sample
  title="Avatar with active indicator"
  code="&lt;br-avatar image=&quot;./assets/person.jpg&quot;&gt;&lt;/br-avatar&gt;">
  <br-avatar active="true" image="./assets/person.jpg"></br-avatar>
</br-styleguide-sample>
