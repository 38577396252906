<br-component-page-intro title="Selection Controls"></br-component-page-intro>

<br-styleguide-sample
  title="Checkbox"
  code="
    &lt;br-checkbox idName=&quot;checkbox-1&quot;&gt;Label&lt;/br-checkbox&gt;
    &lt;br-checkbox idName=&quot;checkbox-2&quot;&gt;Label&lt;/br-checkbox&gt;
  ">
  <br-checkbox idName="checkbox-1">Label</br-checkbox>
  <br-checkbox idName="checkbox-2">Label</br-checkbox>
</br-styleguide-sample>

<br-styleguide-sample
  title="Radio buttons"
  code="
    &lt;br-radio-button&gt;Label&lt;/br-radio-button&gt;
    &lt;br-radio-button idName=&quot;radio-2&quot;&gt;Label&lt;/br-radio-button&gt;
  ">
  <br-radio-button>Label</br-radio-button>
  <br-radio-button idName="radio-2">Label</br-radio-button>
</br-styleguide-sample>
