import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  @Input() skin?: string = 'default';
  @Input() layout?: string;
  @Input() icon?: string;
  @Input() block?: boolean = false;
  @Input() size?: string;
  @Input() dataToggle?: string;
  @Input() dataTarget?: string;

  getClass(layout, block, size){
    return [this.getLayoutClass(layout), this.getBlockClass(block), this.getSizeClass(size)];
  }

  getLayoutClass(layout) {
    var layoutClassList='';
    if(layout == "icon-only"){
      layoutClassList = 'btn--icon';
    }else if (layout == "icon-right"){
      layoutClassList = 'btn--icon-right';
    }else {
      layoutClassList = '';
    }
    return layoutClassList;
  }

  getBlockClass(block) {
    var blockClassList='';
    if(block) {
      blockClassList = 'btn-block';
    }
    return blockClassList;
  }

  getSizeClass(size) {
    var sizeClassList='';
    if(size == "small"){
      sizeClassList = 'btn-sm';
    }else if (size == "large"){
      sizeClassList = 'btn-lg';
    }
    return sizeClassList;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
