<br-component-page-intro
  title="Scoring"
>
  <p>This page documents the scoring component.</p>
</br-component-page-intro>


<br-styleguide-sample
  title="Default"
  code="
    &lt;br-scoring&gt;&lt;/br-scoring&gt;
  ">
  <br-scoring></br-scoring>
</br-styleguide-sample>
