import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './items.component.html',
  styles: [
  ]
})
export class DataItemsComponent implements OnInit {

  projects = DataHandler.getData('data/items/projects')
  items = DataHandler.getData('data/items/items')

  constructor() { }

  ngOnInit(): void {
  }

}
