import { Component, OnInit, Input } from '@angular/core';
import { AppModule } from 'src/app/app.module';
import { DataHandler } from 'src/data/handler'

@Component({
  selector: 'br-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  @Input() sidePanelOpen?: boolean = false;
  @Input() projectName?: string = null;

  detailItems = DataHandler.getData('roadmap/projects/detailItems')
  strategies = DataHandler.getData('roadmap/projects/strategies')
  projects = DataHandler.getData('roadmap/projects/projects')
  
  constructor() { }

  ngOnInit(): void {
  }

  public getDetailItems(projectName) {
    return this.detailItems.filter(item => item.project == projectName)
  }

}
