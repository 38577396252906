import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'br-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() idName?: string;
  @Input() size?: string;
  constructor() { }

  getClass(size){
    if (size == "large") {
      return "modal-lg"
    } else if (size == "x-large") {
      return "modal-xl"
    } else if (size == "small") {
      return "modal-sm"
    } else {
      return "";
    }
  }

  ngOnInit(): void {
  }

}
