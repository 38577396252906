import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './card.component.html',
})
export class CardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
