<div ngbDropdown class="d-inline-block">
  <div class="c-user-menu" id="dropdown-user-menu" ngbDropdownToggle>
    <br-avatar image="{{image}}">{{initials}}</br-avatar>
    <div class="c-user-menu__details">
      <span class="c-user-menu__name">{{name}}</span>
      <span class="c-user-menu__company">{{company}}</span>
    </div>
    <br-icon name="caret-down"></br-icon>
  </div>

  <div ngbDropdownMenu aria-labelledby="dropdown-user-menu" class="dropdown-menu">
    <a class="dropdown-item" href="#">Logout</a>
  </div>
</div>
