export const icons = [
  'add',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'edit',
  'delete',
  'check',
  'caret-down',
  'caret-up',
  'caret-right',
  'close',
  'minus',
  'filter',
  'drag',
  'circle-check',
  'circle-info',
  'warning',
  'time-line'
];
