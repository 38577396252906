<div class="compensate-header">
  <br-navbar isFixed="true">
    <br-toolbar>
      <br-toolbar-group position="left">
        <br-toolbar-item>
          <h3 class="bc-toolbar__title">Data</h3>
        </br-toolbar-item>
        <br-toolbar-item>
          <br-tabs>
            <br-tab route="/data/projects">Projects</br-tab>
            <br-tab route="/data/programs">Programs</br-tab>
            <br-tab route="/data/items">Items</br-tab>
          </br-tabs>
        </br-toolbar-item>
      </br-toolbar-group>
      <br-toolbar-group position="right">
        <br-toolbar-item>
          <form class="form-inline">
            <div class="form-group">
              <label class="mr-3" for="showItem">Show</label>
              <select class="form-control" id="showItem" (change)="selectChangeHandler($event)">
                <option value="Items">Items</option>
                <option value="Programs">Programs</option>
              </select>
            </div>
          </form>
        </br-toolbar-item>
        <br-toolbar-item>
          <div ngbDropdown class="d-inline-block" placement="bottom-right">
            <br-button id="filter-dropdown" ngbDropdownToggle icon="caret-down" layout="icon-right">Filter</br-button>
            <div ngbDropdownMenu aria-labelledby="filter-dropdown" class="p-3">
              <form>
                <div class="form-group">
                  <label>Score</label>
                  <br-checkbox idName="checkbox-1">
                    <br-badge value="1">1</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-2">
                    <br-badge value="2">2</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-3">
                    <br-badge value="3">3</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-4">
                    <br-badge value="4">4</br-badge>
                  </br-checkbox>
                  <br-checkbox idName="checkbox-5">
                    <br-badge value="5">5</br-badge>
                  </br-checkbox>
                </div>
              </form>
              <div class="dropdown-footer">
                <br-button-toolbar>
                  <br-button-toolbar-item>
                    <br-button size="small">Reset</br-button>
                  </br-button-toolbar-item>
                  <br-button-toolbar-item>
                    <br-button size="small" skin="primary">Filter</br-button>
                  </br-button-toolbar-item>
                </br-button-toolbar>
              </div>
            </div>
          </div>
        </br-toolbar-item>
        <br-toolbar-item>
          <br-button routerLink="/data/brainstorm">Go to brainstorm</br-button>
        </br-toolbar-item>
        <br-toolbar-item>
          <br-button icon="add" skin="primary">Add item</br-button>
        </br-toolbar-item>
      </br-toolbar-group>
    </br-toolbar>
  </br-navbar>
  <div class="compensate-header overflow-hidden">
    <div class="overflow-auto h-100">
      <table class="table">
        <thead>
          <tr>
            <th class="table-cell-icon" scope="col"></th>
            <th scope="col">Project</th>
            <th scope="col">Project type</th>
            <th scope="col">Project value</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let project of projects">
            <tr>
              <td>
                <br-button *ngIf="!project.collapsed" (click)="project.collapsed = !project.collapsed" skin="tertiary" icon="caret-down" layout="icon-only">Toggle</br-button>
                <br-button *ngIf="project.collapsed" (click)="project.collapsed = !project.collapsed" skin="tertiary" icon="caret-right" layout="icon-only">Toggle</br-button>
              </td>
              <td>{{project.project}}</td>
              <td class="u-muted">{{project.type}}</td>
              <td>
                <br-badge value="{{project.value}}">{{project.value}}</br-badge>
              </td>
            </tr>
            <tr *ngIf="!project.collapsed" class="c-table__collapse-content">
              <td colspan="99" class="p-0">
                <table *ngIf="selectedView == 'Items'">
                  <thead>
                    <tr>
                      <th class="table-cell-icon"></th>
                      <th scope="col">Item</th>
                      <th scope="col">Type</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of audience">
                      <tr *ngIf="item.project ==  project.project">
                        <td></td>
                        <td>{{item.audience}}</td>
                        <td class="u-muted">Audience</td>
                        <td>
                          <br-badge value="{{item.value}}">{{item.value}}</br-badge>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngFor="let item of skills">
                      <tr *ngIf="item.project ==  project.project">
                        <td></td>
                        <td>{{item.skills}}</td>
                        <td class="u-muted">Skills</td>
                        <td>
                          <br-badge value="{{item.value}}">{{item.value}}</br-badge>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngFor="let item of data">
                      <tr *ngIf="item.project ==  project.project">
                        <td></td>
                        <td>{{item.data}}</td>
                        <td class="u-muted">Data</td>
                        <td>
                          <br-badge value="{{item.value}}">{{item.value}}</br-badge>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngFor="let item of processes">
                      <tr *ngIf="item.project ==  project.project">
                        <td></td>
                        <td>{{item.processes}}</td>
                        <td class="u-muted">Processes</td>
                        <td>
                          <br-badge value="{{item.value}}">{{item.value}}</br-badge>
                        </td>
                      </tr>
                    </ng-container>
                    <tr>
                      <td></td>
                      <td colspan="6">
                        <br-button size="small" icon="add">Add item to project</br-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table *ngIf="selectedView == 'Programs'">
                  <thead>
                    <tr>
                      <th class="table-cell-icon"></th>
                      <th scope="col">Program</th>
                      <th scope="col">Type</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of programItems">
                      <tr *ngIf="item.project ==  project.project">
                        <td></td>
                        <td>{{item.program}}</td>
                        <td class="u-muted">{{item.type}}</td>
                        <td>
                          <br-badge value="{{item.value}}">{{item.value}}</br-badge>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>

              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
