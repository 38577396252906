<br-navbar isFixed="true">
  <br-toolbar>
    <br-toolbar-group position="left">
      <br-toolbar-item>
        <br-button routerLink="/projects-objectives/objectives" skin="tertiary" icon="arrow-left" layout="icon-only">Back</br-button>
      </br-toolbar-item>
      <br-toolbar-item>
        <div class="d-flex flex-column">
          <span class="u-muted c-body-2">Projects &amp; Objectives</span>
          <h3 class="bc-toolbar__title">Objective A</h3>
        </div>
      </br-toolbar-item>
    </br-toolbar-group>
    <br-toolbar-group position="right">
      <br-toolbar-item>
        <br-button skin="primary">Save</br-button>
      </br-toolbar-item>
    </br-toolbar-group>
  </br-toolbar>
</br-navbar>
<div class="compensate-header d-flex flex-column overflow-auto flex-nowrap">
  <div class="container-md mt-5 mb-5">
    <br-card>
      <br-card-header title="Objective details"></br-card-header>
      <br-card-body>
        <form>
          <div class="form-group">
            <label for="name">Name</label>
            <input id="name" type="text" class="form-control" value="Objective A">
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea class="form-control" id="description" rows="3">Increase sales within Europe in the short term</textarea>
          </div>
          <div class="form-group">
            <label for="select">Strategy</label>
            <select class="form-control" id="select" value="Growth">
              <option>Growth</option>
              <option>Digital transfer</option>
              <option>Career coach</option>
            </select>
          </div>
          <div class="form-group">
            <label class="d-block" for="description">Objective value</label>
            <br-scoring></br-scoring>
          </div>
        </form>
      </br-card-body>
    </br-card>

    <br-card>
      <br-card-header title="Projects contributing to objective"></br-card-header>
      <br-card-body>
        <form>
          <div class="form-group d-flex align-items-center">
            <input id="name" type="text" class="form-control mr-2" placeholder="Search projects...">
            <br-button icon="add" layout="icon-only">Add</br-button>
          </div>
        </form>
        <table class="table">
          <thead>
            <tr class="row">
              <th class="col-sm-6" scope="col">Name</th>
              <th class="col-sm-2" scope="col">Value</th>
              <th class="col-sm-2" scope="col">Contribution</th>
              <th class="col-sm-2" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="row">
              <td class="col-sm-6">Project 1</td>
              <td class="col-sm-2">
                <br-badge value="5">5</br-badge>
              </td>
              <td class="col-sm-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">%</span>
                  </div>
                  <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
                </div>
              </td>
              <td class="col-sm-2">
                <br-button-toolbar>
                  <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
                </br-button-toolbar>
              </td>
            </tr>
            <tr class="row">
              <td class="col-sm-6">Project 5</td>
              <td class="col-sm-2">
                <br-badge value="3">3</br-badge>
              </td>
              <td class="col-sm-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">%</span>
                  </div>
                  <input type="text" class="form-control" value="75" aria-label="Value" aria-describedby="basic-addon1">
                </div>
              </td>
              <td class="col-sm-2">
                <br-button-toolbar>
                  <br-button skin="tertiary" icon="delete" layout="icon-only">Delete</br-button>
                </br-button-toolbar>
              </td>
            </tr>
          </tbody>
        </table>
      </br-card-body>
    </br-card>
  </div>

</div>
