import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './audience.component.html',
  styles: [
  ]
})
export class BrainstormProgramsAudienceComponent implements OnInit {
  
  programs = DataHandler.getData('data/brainstorm/programs/audience/programs')
  items = DataHandler.getData('data/brainstorm/programs/audience/items')

  constructor() { }

  ngOnInit(): void {
  }

}
