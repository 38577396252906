import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './checkbox-tree.component.html'
})
export class CheckboxTreeComponent implements OnInit {
  nodes = [
    {
      name: 'Parent 1',
      id: '1',
      expanded: false,
      children: [
        { name: 'Child 1.1', id: '1.1' },
        { name: 'Child 1.2', id: '1.2' }
      ]
    },
    {
      name: 'Parent 2',
      id: '2',
      expanded: true,
      isChecked: true,
      children: [
        { name: 'Child 2.1', id: '2.1' },
        { name: 'Child 2.2', id: '2.2' }
      ]
    },
    {
      name: 'Parent 3',
      id: '3',
      expanded: false,
      children: [
        { name: 'Child 3.1', id: '3.1' },
        { name: 'Child 3.2', id: '3.2' }
      ]
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
