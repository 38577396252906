import { Component, OnInit } from '@angular/core';
import { DataHandler } from 'src/data/handler'

@Component({
  templateUrl: './skills.component.html',
  styles: [
  ]
})
export class BrainstormProgramsSkillsComponent implements OnInit {
  
  programs = DataHandler.getData('data/brainstorm/programs/skills/programs')
  items = DataHandler.getData('data/brainstorm/programs/skills/items')

  constructor() { }

  ngOnInit(): void {
  }

}
